import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import ProtectedRoute from "./components/shared/protectedRoute/ProtectedRoute";
import NotFound from "./components/shared/errors/NotFound";
import ScrollToTopBtn from "./components/shared/scroll/ScrollToTopBtn";
import ErrorBoundary from "./components/shared/errorBoundary/ErrorBoundary";

import TermsForBuyer from "./components/buyer/termsForBuyer/TermsForBuyer";
import { PageLoading } from "./components/shared/message/Message";
import ErrorBoundary2 from "./components/shared/errorBoundary/ErrorBoundary2";
import { ToastHandler } from "./components/shared/Toast/ToastHandler";
import queryString from "query-string";
import { useLocation } from 'react-router-dom'
import {
  clearErrorToast,
  trackPopUp,
} from "./redux/dispatchers/userDispatcher";
import RestaurantSetup from "./components/completeRestaurantSetup/CompleteRestaurantSetup";
import Footer from "./components/shared/footer/Footer";
import {  getCookieForEntity } from "./utils/helpers";
import { HP_WEB_SERVICE_PORT } from "./utils/constants/others";
import RequestProductRedirection from "./components/buyer/requestProduct/RequestProductRedirection";
import ProfileSettingsRedirection from "./components/buyer/profile/ProfileSettingsRedirection";
import ContactRedirection from "./components/contact/ContactRedirection";
import QueriesRedirection from "./components/contact/QueriesRedirection";
import CouponRedirection from "./components/claimOfferCoupon/CouponRedirection";
import OrderRedirection from "./components/buyer/order/OrderRedirection";
import OrderDetailRedirection from "./components/buyer/order/OrderDetailRedirection";
import OrderNumberRedirection from "./components/buyer/order/OrderNumberRedirection";

const PayLater = React.lazy(() =>
  import("./components/shared/static/PayLater")
);
const FAQAnswer = React.lazy(() => import("./components/contact/FAQAnswer"));
const ProductDescription = React.lazy(() =>
  import("./components/buyer/productDescription/ProductDescription")
);
const SuccessPage = React.lazy(() =>
  import("./components/shared/success/SuccessPage")
);
const ForgotPassword = React.lazy(() =>
  import("./components/auth/signIn/ForgotPassword")
);
const SignIn = React.lazy(() => import("./components/auth/signIn/SignIn"));
const Register2 = React.lazy(() =>
  import("./components/auth/register/Register2")
);
const Ownersdashboard = React.lazy(() =>
  import("./components/ownersDashboard/OwnersDashboard")
);
const EmailVerification = React.lazy(() =>
  import("./components/buyer/email/EmailVerification")
);
const Banner = React.lazy(() => import("./components/buyer/banner/Banner"));

const DiwaliCampaign = React.lazy(() =>
  import("./components/campaigns/DiwaliCampaign")
);
const CityExitPage = React.lazy(() =>
  import("./components/campaigns/CityExitPage")
);

const AddsOfferCampaign = React.lazy(() =>
  import("./components/campaigns/AddsOfferCampaign")
);

const AddsCampaign = React.lazy(() =>
  import("./components/campaigns/AddsCampaign")
);

const DiwaliOfferCampaign = React.lazy(() =>
  import("./components/campaigns/DiwaliOfferCampaign")
);

const LinkedInReactivation = React.lazy(() =>
  import("./components/campaigns/LinkedInReactivation")
);

const Checkout = React.lazy(() =>
  import("./components/buyer/checkout/Checkout")
);
const Outlet = React.lazy(() => import("./components/buyer/outlet/Outlet"));
const Credit = React.lazy(() => import("./components/buyer/credit/Credit"));
const Cart = React.lazy(() => import("./components/buyer/cart/Cart"));
const CreditLimit = React.lazy(() =>
  import("./components/buyer/credit/CreditLimit")
);
const Payment = React.lazy(() => import("./components/buyer/payment/Payment"));
const PaymentResult = React.lazy(() =>
  import("./components/buyer/paymentResult/PaymentResult")
);
const MilestoneBanner = React.lazy(() =>
  import("./components/buyer/home/MilestoneDetails")
);

const PaymentSuccess = React.lazy(() =>
  import("./components/buyer/paymentSuccess/PaymentSuccess")
);

const Order = React.lazy(() => import("./components/buyer/order/Order"));
const MxWeb = React.lazy(() => import("./components/auth/mxWeb/MXWeb"));
const OrderSuccess = React.lazy(() =>
  import("./components/buyer/orderSuccess/OrderSuccess")
);
const OrderDetail = React.lazy(() =>
  import("./components/buyer/orderDetail/OrderDetailV2")
);

const HpCredit = React.lazy(() => import("./components/hpCredit/HpCredit"));

const CreditOnboarding = React.lazy(() =>
  import("./components/hpCredit/CreditOnboarding")
);

const QRPayment = React.lazy(() =>
  import("./components/buyer/qrPayment/QRPayment")
);

const HandleCatalog = React.lazy(() =>
  import("./components/handleCatalog/HandleCatalog")
);
const HandleSearch = React.lazy(() =>
  import("./components/handleSearch/HandleSearch")
);
const HandleHome = React.lazy(() =>
  import("./components/handleHome/HandleHome")
);

const Serviceable = React.lazy(() =>
  import("./components/serviceable/Serviceable")
);
const AcquisitionDetails = React.lazy(() =>
  import("./components/buyer/rewards/RewardsV2")
);

// const AcquisitionDetailsV2 = React.lazy(() =>
//   import("./components/buyer/rewards/RewardsV2")
// );

const DownloadDaApp = React.lazy(() =>
  import("./components/shared/download/DownloadDaApp")
);
const NpsUserRating = React.lazy(() =>
  import("./components/buyer/npsuserrating/NpsUserRating")
);

const HappyOnboarding = React.lazy(() =>
  import("./components/auth/register/HappyOnboarding")
);
const PastOrders = React.lazy(() =>
  import("./components/pastOrders/PastOrders")
);
const IosInstall = React.lazy(() => import("./components/shared/IosInstall"));
const Terms = React.lazy(() => import("./components/shared/terms/Terms"));
const WasteBuyerTerms = React.lazy(() =>
  import("./components/shared/terms/WasteBuyerTerms")
);

const CorporateAnnouncements = React.lazy(() =>
  import("./components/shared/CorporateAnnouncements/CorporateAnnouncements")
);

const DeleteAccount = React.lazy(() =>
  import("./components/shared/deleteAccount/DeleteAccount")
);

const RestaurantSetupPage = React.lazy(() =>
  import("./components/completeRestaurantSetup/CompleteRestaurantSetup")
);

class RouteHandler extends React.Component {
  prepareAndSendTracing() {
    let params = queryString.parse(this.props.location.search);
    let trackParams = {
      ActionType: params.action_type,
      ReferenceId: Number(params.reference_id),
      Element: params.element,
      Source: params.source,
      ReferenceType: params.reference_type,
    };
    if (
      trackParams?.ActionType?.length &&
      trackParams?.Element?.length &&
      trackParams?.Source?.length &&
      trackParams?.ReferenceType?.length
    ) {
      this.props.actions.trackPopUp(trackParams);
    }
  }

  componentDidMount() {
    const token = getCookieForEntity("token", "posToken");
    if (token && token.trim().length > 0) {
      this.prepareAndSendTracing();
    }
  }

  componentDidUpdate(){
    let excludeARR = ["/Terms","/",""];
    if (excludeARR.indexOf(this.props.location.pathname) >-1){
        if (process.env.NODE_ENV === "development") {
          window.location.href = `http://localhost:${HP_WEB_SERVICE_PORT}/`;
        } else {
          window.location.reload();
        }
    }
  }

  CurrentRoute = () => {
    return window.location.href
  }

  render() {
    return (
      <div
        style={{
          paddingTop: this.props.showAppInstallPrompt ? !this.CurrentRoute().includes("/complete-restaurant-setup") ?  "60px" : "0px" : "0px",
        }}
        className="site-content"
      >
         <ToastHandler
        show={Object.keys(this.props.errorToast)?.length}
        text={this.props.errorToast?.Text}
        Image= {this.props.errorToast?.ImagePath}
        onClose={() => {
          this.props.actions.clearErrorToast()
        }}
        className="info-toast w-400"
      />
        <ErrorBoundary>
          <ScrollToTopBtn />
          <TermsForBuyer />
          <Switch>
            <Route
              path="/success/:type"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <SuccessPage />
                </Suspense>
              )}
            />
            {/*auth routes*/}
            {/* <ProtectedRoute
              path="/signIn/forgotPassword"
              forAuth={false}
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <ForgotPassword />
                </Suspense>
              )}
            />
            <ProtectedRoute
              path="/signin"
              forAuth={false}
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <SignIn />
                </Suspense>
              )}
            />
            <ProtectedRoute
              path="/register"
              forAuth={false}
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <Register2 {...props} />
                </Suspense>
              )}
            /> */}
            <Route
              path="/email-verification/:userId/:token"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <EmailVerification />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              path="/buyer/banner"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <Banner />
                </Suspense>
              )}
            />

            <ProtectedRoute
              forAuth={true}
              path="/buyer/cart"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <Cart />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              path="/buyer/outlet"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <Outlet />
                </Suspense>
              )}
            />

            <ProtectedRoute
              forAuth={true}
              path="/buyer/credit"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <Credit />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              path="/buyer/credit-limit"
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <HpCredit {...props} />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              path="/buyer/credit-onboarding"
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <CreditOnboarding {...props} />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              path="/buyer/payment/result"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <PaymentResult />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              path="/buyer/payment/success"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <PaymentSuccess />
                </Suspense>
              )}
            />
            <Route
              path="/milestoneDetails"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <MilestoneBanner />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              path="/buyer/payment"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <Payment />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              path="/invoices-detail/:orderNumber"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <QRPayment />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              path="/buyer/checkout"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <Checkout />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              path="/buyer/order/success"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <OrderSuccess />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              path="/buyer/order/:orderNumber/:type"
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  {/* <OrderDetail
                    {...props}
                    key={props.match.params.orderNumber}
                  /> */}
                  <OrderNumberRedirection orderNumber={props.match.params.orderNumber} type={props.match.params.type} />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              path="/buyer/order/:orderID"
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  {/* <OrderDetail {...props} key={props.match.params.orderID} /> */}
                  <OrderDetailRedirection orderId={props.match.params.orderID} />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              path="/buyer/order"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <OrderRedirection />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              path="/buyer/profile"
              render={() => (
                <ErrorBoundary2
                  show
                  isPage
                  message="Profile couldn't be loaded"
                >
                  <Suspense
                    fallback={
                      <React.Fragment>
                        <PageLoading />
                      </React.Fragment>
                    }
                  >
                    <ProfileSettingsRedirection/>
                  </Suspense>
                </ErrorBoundary2>
              )}
            />
            {/*Common routes based on signedState*/}
            <Route
              path="/buyer/catalog/:categoryId/:subCategoryId"
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <HandleCatalog {...props} path="catalog" />
                </Suspense>
              )}
            />
            <Route
              path="/buyer/catalog/:categoryName"
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <HandleCatalog {...props} path="catalog" />
                </Suspense>
              )}
            />
            <Route
              path="/buyer/catalog"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <HandleCatalog path="catalog" />
                </Suspense>
              )}
            />
            <ProtectedRoute
              path="/buyer/query/:title/search"
              forAuth={true}
              key={"withQuery"}
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <HandleSearch queryFromRoute={true} />
                </Suspense>
              )}
            />
            <ProtectedRoute
              path="/serviceable/:type"
              forAuth={true}
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <Serviceable />
                </Suspense>
              )}
            />
            <ProtectedRoute
              path="/diwaliCampaign"
              forAuth={true}
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <DiwaliCampaign />
                </Suspense>
              )}
            />
            <ProtectedRoute
              path="/cityExitPage"
              forAuth={true}
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <CityExitPage />
                </Suspense>
              )}
            />
            <ProtectedRoute
              path="/contact/queries"
              forAuth={true}
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <QueriesRedirection />
                </Suspense>
              )}
            />
            <ProtectedRoute
              path="/contact"
              forAuth={true}
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <ContactRedirection />
                </Suspense>
              )}
            />
            <ProtectedRoute
              path="/coupon"
              forAuth={true}
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <CouponRedirection/>
                </Suspense>
              )}
            />
            <ProtectedRoute
              path="/owner-dashboard"
              forAuth={true}
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <Ownersdashboard {...props} />
                </Suspense>
              )}
            />
            <ProtectedRoute
              path="/faq"
              forAuth={true}
              render={() => (
                <ErrorBoundary2 show isPage message="FAQs couldn't be loaded">
                  <Suspense
                    fallback={
                      <React.Fragment>
                        <PageLoading />
                      </React.Fragment>
                    }
                  >
                    <FAQAnswer />
                  </Suspense>
                </ErrorBoundary2>
              )}
            />
            <ProtectedRoute
              path="/buyer/rewards"
              forAuth={true}
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <AcquisitionDetails />
                </Suspense>
              )}
            />
            <Route
              path="/buyer/search"
              key={"withoutQuery"}
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <HandleSearch />
                </Suspense>
              )}
            />
            <Route
              path="/ota/ios-install"
              render={() => (
                <ErrorBoundary2
                  show
                  isPage
                  message="Profile couldn't be loaded"
                >
                  <Suspense
                    fallback={
                      <React.Fragment>
                        <PageLoading />
                      </React.Fragment>
                    }
                  >
                    <IosInstall />
                  </Suspense>
                </ErrorBoundary2>
              )}
            />
            <ProtectedRoute
              path="/paylater"
              forAuth={true}
              render={() => (
                <ErrorBoundary2
                  show
                  isPage
                  message="Profile couldn't be loaded"
                >
                  <Suspense
                    fallback={
                      <React.Fragment>
                        <PageLoading />
                      </React.Fragment>
                    }
                  >
                    <PayLater />
                  </Suspense>
                </ErrorBoundary2>
              )}
            />

            <Route
              path="/buyer/past-orders/:date"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <PastOrders />
                </Suspense>
              )}
            />

            <Route
              path="/buyer/past-orders"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <PastOrders />
                </Suspense>
              )}
            />

            <Route
              path="/request-product"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <RequestProductRedirection />
                </Suspense>
              )}
            />

            

            {/*public routes*/}
            <Route
              path="/buyerterms"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <Terms />
                </Suspense>
              )}
            />
             <Route
              path="/Terms"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <Terms />
                </Suspense>
              )}
            />
            <Route
              path="/wastebuyerterms"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <WasteBuyerTerms />
                </Suspense>
              )}
            />
            <ProtectedRoute
              forAuth={true}
              forCombineAuth={true}
              path="/"
              exact={true}
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <HandleHome {...props} />
                </Suspense>
              )}
            />

            <Route
              path="/zomato/onboarding/"
              signOutFirst={true}
              forAuth={true}
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <HappyOnboarding {...props} />
                </Suspense>
              )}
            />

            <Route
              path="/partnerweb/onboarding/"
              signOutFirst={true}
              forAuth={true}
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <MxWeb {...props} />
                </Suspense>
              )}
            />
            <Route
              path="/downloads/apk/:appType"
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <DownloadDaApp {...props} />
                </Suspense>
              )}
            />

            <Route
              path="/npsuserrating/:rating/:token"
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <NpsUserRating {...props} />
                </Suspense>
              )}
            />

            <ProtectedRoute
              forAuth={true}
              path="/buyer/product-detail/:id"
              render={(props) => (
                <ErrorBoundary2
                  show
                  isPage
                  message="Product detail couldn't be loaded"
                >
                  <Suspense
                    fallback={
                      <React.Fragment>
                        <PageLoading />
                      </React.Fragment>
                    }
                  >
                    <ProductDescription {...props} />
                  </Suspense>
                </ErrorBoundary2>
              )}
            />
            <ProtectedRoute
              path="/addsOfferCampaign"
              forAuth={true}
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <AddsOfferCampaign />
                </Suspense>
              )}
            />
            <ProtectedRoute
              path="/addsCampaign"
              forAuth={true}
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <AddsCampaign />
                </Suspense>
              )}
            />
            <ProtectedRoute
              path="/diwaliOfferCampaign"
              forAuth={true}
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <DiwaliOfferCampaign />
                </Suspense>
              )}
            />
            <Route
              forAuth={true}
              path="/buyer/entity/:entityType/:entityId/:subEntityId"
              render={(props) => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <HandleCatalog {...props} path="catalog" />
                </Suspense>
              )}
            />
            <Route
              path="/linkedin-reactivation"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <LinkedInReactivation />
                </Suspense>
              )}
            />
            <Route
              path="/complete-restaurant-setup"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <RestaurantSetupPage />
                </Suspense>
              )}
            />

            <Route
              path="/z/corporate-announcements"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <CorporateAnnouncements />
                </Suspense>
              )}
            />

            <Route
              path="/deleteAccount"
              render={() => (
                <Suspense
                  fallback={
                    <React.Fragment>
                      <PageLoading />
                    </React.Fragment>
                  }
                >
                  <DeleteAccount />
                </Suspense>
              )}
            />
            
            <Route render={() => <NotFound />} />
          </Switch>
          <Footer
            searchParams={queryString.parse(this.props.location.search)}
          />
        </ErrorBoundary>
      </div>
    );
  }
}
const actions = {
  clearErrorToast,
  trackPopUp,
};
export default withRouter(
  connect(
    (state) => ({
      showAppInstallPrompt: state.user.showAppInstallPrompt,
      errorToast: state.errorHandler.errToast || {},
    }),
    (dispatch) => ({
      actions: bindActionCreators(actions, dispatch),
    })
  )(RouteHandler)
);
