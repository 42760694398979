import React, { useEffect, useState } from "react";
import _isEmpty from "lodash-es/isEmpty";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./DatePicker.scss";  

function Datepicker (props) {
  return (
    <DatePicker
    dateFormat={props.dateFormat}
    showMonthDropdown={props.showMonthDropdown}
    showYearDropdown={props.showYearDropdown}
    yearDropdownItemNumber={props.yearDropdownItemNumber}
    selected={props.selected}
    onChange={props.onChange}
    onChangeRaw={(e) => e.preventDefault()}
    customInput={props.customInput}
    disabled = {props.disabled}
    className={`${props.className}`}
    maxDate={new Date()}
/>
  )
}

export default Datepicker