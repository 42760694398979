import actionTypes from "../actionTypes";

const initialState = {
  orderList: {
    orders: [],
    orderTypes: [],
    nextPage: 1,
    hasMore: true,
  },
  order: null,
  inProgress: {
    reOrder: new Set(),
  },
  creditNote: {
    number: null,
    creationDateTime: null,
    deliveryChargeRefundString: "",
    amount: null,
    products: [],
  },
  orderPaymentInfo: null,
  adjustPgCharge: null,
};

function orderReducer(state = initialState, action) {
  const payload = action.payload;

  switch (action.type) {
    case actionTypes.ORDER_ADD_RATING_DONE: {
      const order = state.order ? { ...state.order } : null;
      if (order) {
        order.Rating = {
          OrderRating: payload.orderRating,
        };
      }
      return {
        ...state,
        orderList: {
          ...state.orderList,
          orders: state.orderList.orders.map((order) => {
            if (payload.orderId === order.OrderId) {
              return {
                ...order,
                Rating: {
                  OrderRating: payload.orderRating,
                },
              };
            } else {
              return order;
            }
          }),
        },
        order,
      };
    }
    case actionTypes.CREDIT_NOTE_GET_DONE:
      return {
        ...state,
        creditNote: {
          ...state.creditNote,
          number: payload.number,
          creationDateTime: payload.creationDateTime,
          amount: payload.amount,
          orderNumber: payload.orderNumber,
          products: payload.products,
          deliveryChargeRefundString: payload.deliveryChargeRefundString,
        },
      };

    case actionTypes.ORDER_GET_ALL_BEGIN:
      return {
        ...state,
        orderList: {
          ...state.orderList,
          orders: action.isFirstTime ? [] : [...state.orderList.orders],
        },
      };

    case actionTypes.ORDER_GET_ALL_DONE:
      return {
        ...state,
        orderList: {
          ...state.orderList,
          orders: action.isFirstTime
            ? [...action.orders]
            : [...state.orderList.orders, ...action.orders],
          orderTypes: action.orderTypes,
          nextPage: action.isFirstTime ? 2 : state.orderList.nextPage + 1,
          hasMore: action.orders.length < action.ordersPerPage ? false : true,
        },
      };

    case actionTypes.ORDER_GET_ONE_BEGIN:
      return {
        ...state,
        order: null,
      };

    case actionTypes.ORDER_GET_ONE_DONE:
      return {
        ...state,
        order: action.order,
      };

    case actionTypes.RATING_ORDER_GET_DONE:
      return {
        ...state,
        orderRating: action.orderRating,
      };

    case actionTypes.ORDER_REORDER_BEGIN:
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          reOrder: new Set(state.inProgress.reOrder).add(payload.orderId),
        },
      };

    case actionTypes.ORDER_REORDER_DONE: {
      if (payload.message) {
        const progState = {
          ...state,
          inProgress: {
            ...state.inProgress,
            reOrder: new Set(),
          },
          reOrderSuccesMsg: payload.message,
        };
        return { ...progState };
      }
      return { ...state };
    }

    case actionTypes.ORDER_REORDER_FAIL:
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          reOrder: new Set([
            ...Array.from(state.inProgress.reOrder).filter(
              (orderId) => orderId !== payload.orderId
            ),
          ]),
        },
      };

    case actionTypes.ORDER_CANCEL_DONE:
      return {
        ...state,
        cancelOrder: action.payload,
      };
    case actionTypes.GET_REASONS_DONE:
      return {
        ...state,
        reasons: action.payload,
      };
    case actionTypes.GET_ORDER_PAYMENT_INFO_BEGIN:
      return {
        ...state,
        orderPaymentInfo: {},
      };
    case actionTypes.GET_ORDER_PAYMENT_INFO_DONE:
      return {
        ...state,
        orderPaymentInfo: payload,
      };
    case actionTypes.GET_ORDER_PAYMENT_INFO_FAIL:
      return {
        ...state,
        orderPaymentInfo: {},
      };
    case actionTypes.UPDATE_ORDER_PAYMENT_INFO_BEGIN:
      return {
        ...state,
        orderPaymentInfo: {},
      };
    case actionTypes.UPDATE_ORDER_PAYMENT_INFO_DONE:
      return {
        ...state,
        orderPaymentInfo: payload,
      };
    case actionTypes.UPDATE_ORDER_PAYMENT_INFO_FAIL:
      return {
        ...state,
        orderPaymentInfo: {},
      };
    case actionTypes.ADJUST_PG_CHARGE_BEGIN:
      return {
        ...state,
        adjustPgCharge: {},
      };
    case actionTypes.ADJUST_PG_CHARGE_DONE:
      return {
        ...state,
        adjustPgCharge: payload,
      };
    case actionTypes.ADJUST_PG_CHARGE_FAIL:
      return {
        ...state,
        adjustPgCharge: {},
      };
    default:
      return state;
  }
}

export default orderReducer;
