export const PaymentStatus_Paid = "Paid";
export const PaymentStatus_Pending = "Pending";
export const PaymentStatus_Failed = "Failed";
export const PaymentStatus_Initiated = "Initiated";
export const PaymentStatus_PartiallyPaid = "Partially paid";
export const PaymentStatus_Unpaid = "Unpaid";

export const OrderStatus_Initiated = "Initiated";
export const OrderStatus_Placed = "Placed";
export const OrderStatus_Packed = "Packed";
export const OrderStatus_Cancelled = "Cancelled";
export const OrderStatus_Invoiced = "Invoiced";
export const OrderStatus_Delivered = "Delivered";
export const OrderStatus_Dispatched = "Dispatched";
export const OrderStatus_Returned = "Returned";

export const CartUpdateSource_Catalog = "CATALOG";
export const CartUpdateSource_Category = "CATEGORY";
export const CartUpdateSource_SubCategory = "SUB_CATEGORY";
export const CartUpdateSource_OffersHome = "OFFERS_HOME";
export const CartUpdateSource_OffersAll = "OFFERS_ALL";
export const CartUpdateSource_Keyword = "KEYWORD";
export const CartUpdateSource_Banner = "BANNER";
export const CartUpdateSource_Cart = "CART";
export const CartUpdateSource_SearchQuery = "SEARCH_QUERY";
export const CartUpdateSource_OrderDetail = "ORDER_DETAIL";
export const CartUpdateSource_ModalCart = "MODAL_CART";
export const CartUpdateSource_AlternateRailCart = "ALTERNATE_RAIL_CART";
export const CartUpdateSource_ModalListing = "MODAL_LISTING";
export const CartUpdateSource_AlternateRailListing = "ALTERNATE_RAIL_LISTING";
export const CartUpdateSource_PastOrder = "PAST_ORDERS";
export const CartUpdateSource_ModalPastOrder = "MODAL_PAST_ORDERS";
export const CartUpdateSource_AlternatePastOrder = "ALTERNATE_RAIL_PAST_ORDERS";
export const CartUpdateSource_SimilarProducts = "SIMILAR_PRODUCTS";
export const CartUpdateSource_Forget = "CART_RAIL_DID_YOU_FORGET";
export const CartUpdateSource_ModalCartTrial = "MODAL_CART_TRIAL_OFFERS";

export const SearchCardCount = 4;
export const CatalogCardCount = 3;

export const Reorder_OrderHistory = "REORDER_ORDER_HISTORY";
export const Reorder_OrderDetail = "REORDER_ORDER_DETAIL";
export const Reorder_Home = "REORDER_HOME";
export const Reorder_PastOrders = "REORDER_PAST_ORDERS";

export const CLEAR_CART_USER = "CLEAR_CART_USER";
export const CLEAR_CART_SYSTEM = "CLEAR_CART_SYSTEM";

export const CTA_TYPE_CALL = "CALL";
export const CTA_TYPE_ORDER_PAYMENT = "ORDER_PAYMENT";

export const CTA_COLOUR = "#E03546";
export const SWITCH_COLOUR = "#EF4F5F";
export const DISABLED_COLOR = "#D0D4DC";

export const INPUT_BORDER_COLOR = "#D6D6D6";
export const PROFILE_INPUT_BORDER = "#cfcfcf";
export const HOME_DUE_PAYMENT = "due_order_home";
export const CHECKOUT_DUE_PAYMENT = "due_order_checkout";
export const POD_HOME_AEROBAR = "home";
export const POD_ORDER_HISTORY = "order_history";
export const POD_ORDER_DETAIL = "order_detail";
export const PAYMENT_SRC = "payment_initiation_source";

export const SUMMARY_TAB = "SUMMARY";
export const DELIVERY_TAB = "DELIVERY";
export const ITEMS_TAB = "ITEMS";
export const SUPPORT_TAB = "SUPPORT";

export const ONGOING_OFFERS = "ONGOING_OFFERS";
export const PAST_OFFERS = "PAST_OFFERS";

export const OUTLET_ONBOARDING_STATUS_INITIATED = "Initiated";
export const REDIRECTION_HOME = "HOME";
export const REDIRECTION_CHECKOUT = "CHECKOUT";
export const REDIRECTION_CART = "CART";
export const STATUS_VERIFIED = "Verified";
export const STATUS_VERIFY = "Verify";
export const ENTER_GST_MANUALLY = "Enter GST manually";
export const KEY_FLOOR = "floor";
export const KEY_LIFT = "lift";
export const VALUE_GROUND = "Ground";
export const DOCUMENT_PAN = "pan";
export const DOCUMENT_FSSAI = "fssai";
export const DOCUMENT_GST = "gst";

export const DOCUMENT_TYPE_PAN = "PAN";
export const DOCUMENT_TYPE_FSSAI = "FSSAI";
export const DOCUMENT_TYPE_GST = "GST";

export const KEY_TNC = "TncStatus";
export const KEY_WHATS_APP_NOTIF_STATUS = "WhatsappNotificationStatus";

export const PAN_NUMBER = "panNumber";
export const FSSAI_NUMBER = "fssaiNumber";
export const GST_NUMBER = "gstNumber";
export const PINCODE = "pincode";
export const ADDRESS = "address";
export const EMAIL = "email";

export const LATER_CTA = "I’ll do it later";

export const INVALID_PINCODE_ERROR = "Invalid Pincode";
export const ADDRESS_VALIDATION_ERROR =
  "Address should be of minimum 10 characters";
export const EMAIL_ERROR = "Invalid email address";

export const PAN_ACTION =
  "/consumer/verifyDocumentWihoutOCR?documentType=PAN&documentNumber=";
export const GST_ACTION =
  "/consumer/verifyDocumentWihoutOCR?documentType=GST&documentNumber=";
export const FSSAI_ACTION =
  "/consumer/verifyDocumentWihoutOCR?documentType=FSSAI&documentNumber=";
export const VERIFY_ACTION_ENDPOINT = (docType) => {
  return `/consumer/verifyDocumentWihoutOCR?documentType=${docType}&documentNumber=`;
};

export const ROUTE_WITH_QUERY_CHECKOUT =
  "/complete-restaurant-setup?source=webOnboardingPage&completeRedirection=CHECKOUT";
export const ROUTE_WITH_QUERY_CART =
  "/complete-restaurant-setup?source=webOnboardingPage&completeRedirection=CART";
export const ROUTE_WITH_QUERY_HOME =
  "/complete-restaurant-setup?source=webOnboardingPage&completeRedirection=HOME";

export const GEO_LOCATION_ERROR =
  "Geolocation is not supported in your browser.";

export const ZOMATO_CREDIT_LINE = "zomato_credit_line";

export const VALID_TABS = [SUMMARY_TAB, ITEMS_TAB, DELIVERY_TAB];

export const RATING_MAP = {
  1: {
    text: "Terrible",
    style: "text-red-700",
  },
  2: {
    text: "Bad",
    style: "text-red-700",
  },
  3: {
    text: "Average",
    style: "text-yellow-600",
  },
  4: {
    text: "Good",
    style: "text-dark-green",
  },
  5: {
    text: "Excellent",
    style: "text-dark-green",
  },
};

// error codes
export const INVENTORY_NOT_AVAILABLE = "INVENTORY_NOT_AVAILABLE";
export const USER_DEVICE_UNVERIFIED = "USER_DEVICE_UNVERIFIED";

export const HYPERPURE_HELPLINE_NUMBER = "011-41171717";
export const HYPERPURE_HELPLINE_NUMBER_HYPERLINK = "011-41171717";
export const REDUX_BASED_ACTION_TYPES = [];

export const BANNER_TYPE_STATIC = "STATIC";
export const BANNER_TYPE_DYNAMIC = "DYNAMIC";

export const FILTER_TYPE_SELECT_OPTIONS_DROPDOWN = "SELECT_OPTIONS_DROPDOWN";
export const FILTER_TYPE_SELECTABLE_OPTION = "SELECTABLE_OPTION";

export const BANNER_IMAGE_RATIO_WEB = 1.77;

export const MODAL_TYPE_VERIFY_NUMBER = "VERIFY_NUMBER";
export const MODAL_TYPE_VERIFY_PASSWROD = "SIGN_IN_PASSWORD";
export const MODAL_TYPE_VERIFY_OTP = "SIGN_IN_OTP";
export const MODAL_TYPE_SIGN_UP = "SIGN_UP";
export const MODAL_TYPE_VERIFY_FORGET_PASSWORD_OTP =
  "VERIFY_FORGET_PASSWORD_OTP";
export const MODAL_TYPE_VERIFY_FORGET_PASSWORD_PASSWORD_MATCH =
  "VERIFY_FORGET_PASSWORD_PASSWORD_MATCH";
export const MODAL_TYPE_SIGN_UP_PASSWORD = "SIGN_UP_PASSWORD";
export const MODAL_TYPE_SIGN_UP_OTP = "SIGN_UP_OTP";

export const INPUT_TYPE_MOBILE_NUMBER = "INPUT_TYPE_MOBILE_NUMBER";
export const INPUT_TYPE_OTP = "INPUT_TYPE_OTP";
export const INPUT_TYPE_PASSWORD = "INPUT_TYPE_PASSWORD";

export const GET_PERSONAL_DETAILS = "GET_PERSONAL_DETAILS";
export const GET_RES_LOCATION_DETAILS = "GET_RES_LOCATION_DETAILS";
export const GET_RES_LOCATION_DETAILS_2 = "GET_RES_LOCATION_DETAILS_2";

export const GET_PAN_DETAILS = "GET_PAN_DETAILS";
export const GET_GST_DETAILS = "GET_GST_DETAILS";

export const GET_FSSAI_DETAILS = "GET_FSSAI_DETAILS";
export const GET_UPLOAD_DETAILS = "GET_UPLOAD_DETAILS";

export const COMPLETE_ONBOARDING_SETUP = "COMPLETE_ONBOARDING_SETUP";
export const ADD_LOCATION_SIGN_UP = "ADD_LOCATION_SIGN_UP";
export const RESTAURANT_SETUP = "Complete restaurant setup";
export const ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER =
  "ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER";
export const ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP =
  "ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP";

export const LOGOUT = "Logout";

export const PERSONAL_DETAIL_SUBHEADING =
  "This will be linked to your account and outlet";
export const PERSONAL_DETAIL_HEADING = "Enter your details";
export const CTA_OKAY = "Okay";
export const MANUALLY = "MANUALLY";

export const PERSONAL_DETAIL_NAME = "name";
export const PERSONAL_DETAIL_LABEL = "Your full name";
export const PERSONAL_DETAIL_EMAIL = "Your email ID";

export const OUTLET_NAME = "outletName";
export const RESTAURANT_NAME = "Restaurant name";
export const COMPLETE_ADDRESS_LABEL = "Enter complete address";
export const LABEL_PINCODE = "Pincode";
export const LABEL_FLOOR = "Floor";
export const LABEL_LIFT_AVAILABLE = "Is lift available";
export const LANDMARK = "landmark";
export const NEARBY_LANDMARK = "Nearby landmark";
export const LEGAL_NAME = "legalName";
export const LEGAL_ENTITY_LABEL = "Legal entity name";
export const LABEL_PAN = "PAN number";

export const FLOOR_OPTIONS = [
  "Basement",
  "Ground",
  "1st",
  "2nd",
  "3rd or above",
];
export const LIFT_OPTIONS = ["Yes", "No"];

export const ACTION_TYPE_CALL = "CALL";
export const ACTION_TYPE_EMAIL = "EMAIL";
export const ACTION_TYPE_CHATBOT = "CHATBOT";

//objects
export const PERSONAL_DETAIL_OBJECT = [
  {
    key: PERSONAL_DETAIL_NAME,
    value: "",
    label: PERSONAL_DETAIL_LABEL,
    error: "",
  },
  { key: EMAIL, label: PERSONAL_DETAIL_EMAIL, value: "", error: "" },
];

export const RESTAURANT_DETAIL_OBJECT = [
  { key: OUTLET_NAME, value: "", label: RESTAURANT_NAME, error: "" },
  { key: ADDRESS, value: "", label: COMPLETE_ADDRESS_LABEL, error: "" },
  { key: PINCODE, value: "", label: LABEL_PINCODE, error: "" },
  {
    key: KEY_FLOOR,
    value: "",
    label: LABEL_FLOOR,
    error: "",
    Heading: LABEL_FLOOR,
    options: FLOOR_OPTIONS,
  },
  {
    key: KEY_LIFT,
    value: "",
    label: LABEL_LIFT_AVAILABLE,
    error: "",
    Heading: LABEL_LIFT_AVAILABLE,
    options: LIFT_OPTIONS,
  },
  {
    key: LANDMARK,
    value: "",
    label: NEARBY_LANDMARK,
    error: "",
    Heading: NEARBY_LANDMARK,
  },
];

export const PAN_DETAIL_OBJECT = [
  {
    key: PAN_NUMBER,
    value: "",
    label: LABEL_PAN,
    error: "",
    Button: {
      loaderVisible: false,
      text: STATUS_VERIFY,
      color: "red",
      action: PAN_ACTION,
      disabled: true,
      visible: true,
      queryKey: PAN_NUMBER,
    },
  },
  {
    key: LEGAL_NAME,
    value: "",
    label: LEGAL_ENTITY_LABEL,
    error: "",
    visible: false,
    disabled: true,
  },
];

export const FSSAI_DETAIL_OBJECT = [
  {
    key: FSSAI_NUMBER,
    value: "",
    label: "FSSAI number",
    error: "",
    Button: {
      loaderVisible: false,
      text: STATUS_VERIFY,
      color: "red",
      action: FSSAI_ACTION,
      disabled: true,
      visible: true,
      queryKey: FSSAI_NUMBER,
    },
  },
];

export const GST_DETAIL_OBJECT = [
  {
    key: GST_NUMBER,
    value: "",
    options: [],
    isSelectedIndex: -1,
    selectedValue: "",
    label: "",
    error: "",
    Button: {
      loaderVisible: false,
      text: STATUS_VERIFY,
      color: "red",
      action: GST_ACTION,
      disabled: true,
      visible: false,
      queryKey: GST_NUMBER,
    },
  },
];

export const PAN_DETAIL_OBJECT_V2 = [
  {
    key: "pan_number",
    value: "",
    error: "",
    keyType: "input",
    icon: "",
    editable: true,
    visible: true,
  },
  {
    key: "entity_name",
    value: "",
    error: "",
    keyType: "input",
    icon: "",
    editable: false,
    visible: false,
  },
  {
    key: "dob",
    value: "",
    error: "",
    keyType: "calender",
    icon: "",
    editable: false,
    visible: false,
  },
];

export const UPLOAD_DETAIL_OBJECT = [
  {
    DocumentType: "",
    DocumentHeading: "",
    DocumentSubHeading: "",
    CTAText: "",
    Status: "",
    CTAImage: "",
    ErrorImage: "",
    ErrorString: "",
    value: "",
    jobId: "",
    uploadNudgeMessage: { imageIcon: "", title: "" },
  },
  {
    DocumentType: "",
    DocumentHeading: "",
    DocumentSubHeading: "",
    CTAText: "",
    Status: "",
    CTAImage: "",
    ErrorImage: "",
    ErrorString: "",
    value: "",
    jobId: "",
  },
];

export const PASSWORD_REGEX = [
  {
    label: "min. 6 characters",
    regex: /.{6,}/,
  },
  {
    label: "1 letter",
    regex: /[a-zA-Z]/,
  },
  {
    label: "1 number",
    regex: /\d/,
  },
  {
    label: "1 special character",
    regex: /[!@#&()–{}:;',?/*~$^+=<>]+/,
  },
];

export const BackPressModal = {
  GET_RES_LOCATION_DETAILS_2: true,
  GET_GST_DETAILS: true,
  GET_UPLOAD_DETAILS: true,
  GET_FSSAI_DETAILS: true,
};

export const BackPressModalActionTypes = {
  GET_RES_LOCATION_DETAILS_2: GET_RES_LOCATION_DETAILS,
  GET_GST_DETAILS: GET_PAN_DETAILS,
  GET_UPLOAD_DETAILS: GET_GST_DETAILS,
  GET_FSSAI_DETAILS: GET_GST_DETAILS,
};

export const COOKIE_TTL_FOR_WEB = 60;

export const TRACKING_TYPE_CLICK = "click";
export const TRACKING_ELEMENT_FCS = "fcs_savings";
export const TRACKING_REFERENCE_ACCOUNT_ID = "fcs_savings_account_id";
export const TRACKING_SOURCE = "FoodCostSummaryPage";

export const COLORSCALEFORDASHBOARD = [
  "#8D68D6",
  "#BBD959",
  "#538CEE",
  "#ED8449",
  "#F8D149",
  "#12a2ab",
  "#3c4886",
  "#b8b8b8",
];

export const CHECKOUT_PAYMENT = "CHECKOUT_PAYMENT";
export const UNIVERSAL_POPUP = "UNIVERSAL_POPUP";

export const OWNER_SELECTION = "OWNER_SELECTION";
export const SEND_OTP = "SEND_OTP";
export const SELECTED_OWNER = "SELECTED_OWNER";

export const CLIENT_TYPE_PETPOOJA = "PETPOOJA";

/**
 * The global API version used for web requests.
 *
 * This constant defines the default version of the API that is used in web requests across the application.
 * It is used to ensure consistent communication with the backend.
 *
 * @constant {number}
 * @default 12.1
 */
export const WEB_GLOBAL_API_VERSION = 12.1;

/**
 * The mobile web size.
 *
 * This constant defines the default size used across the application to determine the screen size for mobile web.
 *
 * @constant {number}
 * @default 992px
 */
export const MOBILE_WEB_SCREEN_SIZE = 992;

/**
 * Enum representing different panels in the support modal.
 * @readonly
 * @enum {number}
 */
export const ORDER_SUPPORT_MODAL_PANELS = {
  ITEM_SELECTION: 1,
  ITEM_ISSUE_SELECTION: 2,
  ITEM_ISSUE_SUMMARY: 3,
};

/**
 * Enum representing different Order issue types .
 * @readonly
 * @enum {string}
 */
export const ISSUE_TYPE = {
  PERISHABLE: "PERISHABLE",
  NON_PERISHABLE: "NON_PERISHABLE",
  NOT_ELIGIBLE: "NOT_ELIGIBLE",
};

/**
 * Enum representing different AppMode types .
 * @readonly
 * @enum {string}
 */
export const APP_MODE = {
  STANDARD: "STANDARD",
  EXPRESS: "EXPRESS",
};

/**
 * Enum representing different App types .
 * @readonly
 * @enum {string}
 */
export const APP_TYPE = {
  PARTNER_WEB: "partner_web",
  POS_PARTNER_WEB: "pos_partner_web",
  WEB: "web",
  MOBILE_WEB: "mweb",
};

/**
 * Enum representing different Cookie keys.
 * @readonly
 * @enum {string}
 */
export const COOKIE_KEYS = {
  APP_MODE: "appMode",
  OUTLET_ID: "outletId",
  POS_OUTLET_ID: "posOutletId",
  API_VERSION: "apiVersion",
  ROUTING_CONTEXT: "routing_context",
  TOKEN: "token",
  POS_TOKEN: "posToken",
  POS_SESSION_ID: "posSessionId",
  IS_IFRAME: "isIframe",
};

/**
 * Enum representing different CLIENT types .
 * @readonly
 * @enum {string}
 */
export const CLIENT_TYPE = {
  USER: "user",
  CONSUMER: "consumer",
};

/**
 * Enum representing different HEADER Keys.
 * @readonly
 * @enum {string}
 */
export const HEADER_KEYS = {
  CLIENT: "X-client",
  TRACKING_ID: "X-TrackingId",
  HEADER_ROUTE: "HeaderRoute",
  DEVICE_ID: "DeviceId",
  DEVICE_NAME: "DeviceName",
  API_VERSION: "APIVersion",
  APP_TYPE: "AppType",
  OUTLET_ID: "X-OutletId",
  ROUTING_CONTEXT: "routing_context",
  AUTHORIZATION: "Authorization",
  APP_MODE: "x-appmode",
};

/**
 * Enum representing different AppMode types .
 * @readonly
 * @enum {string}
 */
export const CHECKOUT_MODE = {
  STANDARD: "STANDARD",
  EXPRESS: "EXPRESS",
  COMBINED: "COMBINED",
};

export const HP_WEB_SERVICE_PORT = 3000;

/**
 * @enum {url}
 * @readonly
 * @description pet pooja white listed url.
 */

export const PET_POOJA_WHITELISTED_ORIGIN = {
  SUPPLIER: "https://supplier.petpooja.com",
};

/**
 * Enum representing different Local Storage keys.
 * @readonly
 * @enum {string}
 */
export const LOCAL_STORE_KEYS = {
  POS_STORES_DATA: "posStoresData",
};
export const SOURCE_CHECKOUT = "checkout";
export const SOURCE_ZPL_CHECKOUT = "zpl_checkout";
