import actionTypes from "../actionTypes";
import _cloneDeep from "lodash-es/cloneDeep";

import { CART_DATA } from "../states/cart";
const initialState = {
  orders: {
    placeOrder: [],
    updatePaymentStatus: [],
    makePendingPayment: [],
  },
  checkoutAddress: {},
  minimumOrderValue: null,
  totalTCS: null,
  inProgress: {
    delete: new Set(),
    add: new Set(),
    update: new Set(),
  },
  locationData: {},
  alternateProductSuggestion: null,
  errorAvailableQty: null,
  categories: [],
  deliveries: [],
  cartData: CART_DATA,
  checkoutMetaData: {},
  moveCartProducts: {
    loading: false,
    cartAppMode: "",
    data : [],
  }
};

function getCategoriesWithUpdatedProductQty({
  prevCategories,
  changes,
  removeIfQtyZero = false,
  updatedProduct
}) {
  const categories = _cloneDeep(prevCategories);
  
  for (let category of categories) {
    for (let product of category.Products) {
      if (updatedProduct && updatedProduct.Id === product.Id) {
        product = updatedProduct;
      } else {
        const change = changes.find((el) => el.Id === product.Id);
        if (change) {
          for (const [key, value] of Object.entries(change)) {
            if (key === "Quantity") {
              product.Quantity.CartQuantity = value;
            } else if (value !== undefined) {
              product[key] = value;
            }
          }
        }
      }
    }
  }
  if (removeIfQtyZero) {
    for (let i = 0; i < categories.length; i++) {
      const products = [];
      for (let j = 0; j < categories[i].Products.length; j++) {
        const product = categories[i].Products[j];
        if(product.Id !== updatedProduct.Id) {
          products.push(product);
        }
      }
      categories[i].Products = products;
    }
  }
  
  const newCategories = [];
  for (let i = 0; i < categories.length; i++) {
    const category = categories[i];

    if (category.Products.length !== 0) {
      newCategories.push(category);
    }
  }
  
  return newCategories;
}

function cartReducer(state = initialState, action) {
  const payload = action.payload;

  switch (action.type) {
    /**
     * PAYMENT_MAKE_PENDING
     */
    case actionTypes.PAYMENT_MAKE_PENDING_DONE:
      return {
        ...state,
        orders: {
          ...state.orders,
          makePendingPayment: payload.orders,
        },
      };

    /**
     * PAYMENT_UPDATE
     */
    case actionTypes.PAYMENT_UPDATE_STATUS_DONE:
      return {
        ...state,
        orders: {
          ...state.orders,
          updatePaymentStatus: payload.orders,
        },
      };

    /**
     * ORDER_PLACE
     */
    case actionTypes.ORDER_PLACE_DONE:
      return {
        ...state,
        orders: {
          ...state.orders,
          placeOrder: payload.orders,
        },
      };

    case actionTypes.ORDER_PLACE_FAIL:
      return {
          ...state,
          orderPlaceErrorCode:  action.errorCode,
      };
    /**
     * CART_CHECKOUT
     */
    case actionTypes.CART_CHECKOUT_DONE:
      return {
        ...state,
        orders: {
          ...state.orders,
          minimumValueFreeDelivery: action.minimumValueFreeDelivery,
          checkoutUserData: action.userData,
          deliveryCharge: action.deliveryCharge,
          deliveryChargesNudge: action.deliveryChargesNudge,
          cartSummary: action.cartSummary,
          cartIssues: action.cartIssues,
          cartCount: action.cartCount
        },
        cartIssues: action.cartIssues,
        cartSummary: action.cartSummary,
        isNewFlowEnabled: action.isNewFlowEnabled,
        deliveries: action.deliveries,
        cartCount: action.cartCount,
        settingConfig: action.settingConfig
      };

      case actionTypes.CART_CHECKOUT_FAIL:
        return {
          ...state,
          code: action.code
        };

    case actionTypes.GET_DELIVERY_SLOTS_DONE:
      return {
        ...state,
        delivery_slots: action.payload
      };

    /**
     * CART_CLEAR
     */
    case actionTypes.CART_CLEAR_DONE:
      return {
        ...state,
        categories: []
      };

    /**
     * CART_ADD
     */
    case actionTypes.CART_ADD_BEGIN:
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          add: new Set(state.inProgress.add).add(action.itemId),
        },
      };
    case actionTypes.CART_ADD_DONE:
      return {
        ...state,
        categories: action.categories,
        cartSummary: action.cartSummary,
        cartIssues: action.cartIssues,
        nudgeInfo: action.nudgeInfo,
        bannerInfo: action.bannerInfo,
        bannerV2: action.bannerV2,
        cartCount: action.cartCount,
        inProgress: {
          ...state.inProgress,
          add: new Set([
            ...Array.from(state.inProgress.add).filter(
              (productId) => productId !== action.productId
            ),
          ]),
        },
        errorAvailableQty: null,
      };
    case actionTypes.CART_ADD_FAIL:
      return {
        ...state,
        categories: getCategoriesWithUpdatedProductQty({
          prevCategories: state.categories,
          changes: [{
            Id: action.productId,
            DiscountNudgeMessage: action.nudgeMessage,
            AvailableQty: action.errorAvailableQty,
            // AlreadyAddedQuantityCount
            ...(action.alternateProductSuggestion ?
              {
                ErrorMessage: action.alternateProductSuggestion.ErrorMessage,
                IssueDescription: action.alternateProductSuggestion.IssueDescription,
                IssueHeading: action.alternateProductSuggestion.IssueHeading,
                ModalNudgeMessage: action.alternateProductSuggestion.ModalNudgeMessage,
                RailHeading: action.alternateProductSuggestion.RailHeading,
                SearchQuery: action.alternateProductSuggestion.SearchQuery,
                IssueProduct: action.alternateProductSuggestion.IssueProduct,
              } :
              {}),
          },],
        }),
        inProgress: {
          ...state.inProgress,
          add: new Set([
            ...Array.from(state.inProgress.add).filter(
              (productId) => productId !== action.productId
            ),
          ]),
        },
        alternateProductSuggestion: action.alternateProductSuggestion,
        errorAvailableQty: action.errorAvailableQty,
      };

    /**
     * CART_UPDATE
     */
    case actionTypes.CART_UPDATE_BEGIN:
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          update: new Set(state.inProgress.update).add(action.itemId),
        },
      };

    case actionTypes.CART_UPDATE_DONE:
      state=  {
        ...state,
        categories: action.categories,
        cartSummary: action.cartSummary,
        nudgeInfo: action.nudgeInfo,
        bannerInfo: action.bannerInfo,
        bannerV2: action.bannerV2,
        cartCount: action.cartCount,
        alternateProductSuggestion: {
          ...state.alternateProductSuggestion,
          IssueProduct: action.product && action.product,
        },
        inProgress: {
          ...state.inProgress,
          update: new Set([
            ...Array.from(state.inProgress.update).filter(
              (productId) => productId !== action.productId
            ),
          ]),
        },
        errorAvailableQty: null,
        cartData: {
          ...state.cartData,
          CombinedCartCount: action.CombinedCartCount,
        },
      };
      return state;
    case actionTypes.CART_UPDATE_FAIL:
      return {
        ...state,
        categories: getCategoriesWithUpdatedProductQty({
          prevCategories: state.categories,
          changes: [{
            Id: action.productId,
            DiscountNudgeMessage: action.nudgeMessage,
            AvailableQty: action.errorAvailableQty,
            // AlreadyAddedQuantityCount
            ...(action.alternateProductSuggestion ?
              {
                ErrorMessage: action.alternateProductSuggestion.ErrorMessage,
                IssueDescription: action.alternateProductSuggestion.IssueDescription,
                IssueHeading: action.alternateProductSuggestion.IssueHeading,
                ModalNudgeMessage: action.alternateProductSuggestion.ModalNudgeMessage,
                RailHeading: action.alternateProductSuggestion.RailHeading,
                SearchQuery: action.alternateProductSuggestion.SearchQuery,
                IssueProduct: action.alternateProductSuggestion.IssueProduct,
              } :
              {}),
          },],
        }),
        inProgress: {
          ...state.inProgress,
          update: new Set([
            ...Array.from(state.inProgress.update).filter(
              (productId) => productId !== action.productId
            ),
          ]),
        },
        alternateProductSuggestion: action.alternateProductSuggestion,
        errorAvailableQty: action.errorAvailableQty,
      };

    /**
     * CART_DELETE
     */
    case actionTypes.CART_DELETE_BEGIN:
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          delete: new Set([
            ...Array.from(state.inProgress.delete),
            ...payload.productIds,
          ]),
        },
      };
    case actionTypes.CART_DELETE_DONE:
      return {
        ...state,
        categories: action.payload.categories,
        cartSummary: action.payload.cartSummary,
        cartIssues: action.payload.cartIssues,
        nudgeInfo: action.payload.nudgeInfo,
        bannerInfo: action.payload.bannerInfo,
        bannerV2: action.payload.bannerV2,
        cartCount: action.cartCount,
        alternateProductSuggestion: {
          ...state.alternateProductSuggestion,
          IssueProduct: action.payload.product && action.payload.product,
        },
        inProgress: {
          ...state.inProgress,
          delete: new Set([
            ...Array.from(state.inProgress.delete).filter(
              (productId) => !payload.productIds.includes(productId)
            ),
          ]),
        },
      };
    case actionTypes.CART_DELETE_FAIL:
      return {
        ...state,
        inProgress: {
          ...state.inProgress,
          delete: new Set([
            ...Array.from(state.inProgress.delete).filter(
              (productId) => !payload.productIds.includes(productId)
            ),
          ]),
        },
      };

    /**
     * CART_GET
     */
    case actionTypes.CART_GET_DONE:
      return {
        ...state,
        categories: action.categories,
        cartIssues: action.cartIssues,
        cartSummary: action.cartSummary,
        emptyCartState: action.emptyCartState,
        deleteCartPopup: action.deleteCartPopup,
        cartCount: action.cartCount,
        minimumValueFreeDelivery: action.minimumValueFreeDelivery,
        deliveryCharge: action.deliveryCharge,
        deliveryChargesNudge: action.deliveryChargesNudge,
        minimumOrderValue: action.minimumOrderValue,
        checkoutAddress: { ...action.checkoutAddress },
        totalTCS: action.totalTCS,
        deliveryChargeArray: action.deliveryChargeArray,
        deliveryChargeInfo: action.deliveryChargeInfo,
        nudgeInfo: action.nudgeInfo,
        bannerInfo: action.bannerInfo,
        bannerV2: action.bannerV2,
      };
    
    case actionTypes.CART_V2_GET_DONE:
      return {
        ...state,
        cartData : payload,
        checkoutMode: action.checkoutMetaData,
    };

    case  actionTypes.HP_PLUS_ADD_REMOVE_DONE:
      return{
       ...state,
       hpPlusData: action.hpPlusData,
       SuccessPopupData:action.SuccessPopupData
    };

    case actionTypes.CART_GET_FAIL:
      return {
        ...state,
        code: action.code
      };

    case actionTypes.UPDATE_CHECKOUT_DONE: 
      return {
        ...state,
        orders: {
          ...state.orders,
          minimumValueFreeDelivery: action.minimumValueFreeDelivery,
          checkoutUserData: action.userData,
          deliveryCharge: action.deliveryCharge,
          deliveryChargesNudge: action.deliveryChargesNudge,
          cartSummary: action.cartSummary,
          cartCount: action.cartCount
        },
        isNewFlowEnabled: action.isNewFlowEnabled,
        deliveries: action.deliveries
      };

    case actionTypes.LOCATION_VALIDATE_DONE:
      return {
        ...state,
        locationData: action.locationData
      };

    case actionTypes.GET_DELIVERY_SLOTS_FOR_PACKAGE_DONE: {
      return {
        ...state,
        deliverySlotForPackageData: action.payload
      }
    }

    case actionTypes.GET_DELIVERY_SLOTS_FOR_PACKAGE_INIT: {
      return {
        ...state,
        deliverySlotForPackageData: {}
      }
    }

    case actionTypes.GET_MOVE_CART_PRODUCTS_BEGIN:
      return {
        ...state,
        moveCartProducts:{
          ...state.moveCartProducts,
          loading: true,
        }
      }
    case actionTypes.GET_MOVE_CART_PRODUCTS_DONE:
      return {
        ...state,
        moveCartProducts:{
          data: payload,
          loading: false,
          cartAppMode: action.cartAppMode,
        }
      }
    case actionTypes.GET_MOVE_CART_PRODUCTS_FAIL:
      return {
        ...state,
        moveCartProducts:{
          ...state.moveCartProducts,
          loading: false,
        }
      }
    case actionTypes.SET_MOVE_CART_PRODUCTS_BEGIN:
      return {
        ...state,
        moveCartProducts:{
          ...state.moveCartProducts,
          movingProgress: true,
        }
      }
    case actionTypes.SET_MOVE_CART_PRODUCTS_DONE:
      return {
        ...state,
        moveCartProducts:{
          ...state.moveCartProducts,
          movingProgress: false,
          cartAppMode: action.cartAppMode,
          cartAppModeChanged: !state.moveCartProducts?.cartAppModeChanged
        }
      }
    case actionTypes.SET_MOVE_CART_PRODUCTS_FAIL:
      return {
        ...state,
        moveCartProducts:{
          ...state.moveCartProducts,
          movingProgress: false,
        }
      }

    default:
      return state;
  }
}

export default cartReducer;
