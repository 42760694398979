/**
 * API ENDPOINTS
 */

/**
 * Enum representing different Static Endpoints.
 * @readonly
 * @enum {string}
 */
const STATIC_ENDPOINT = {
    CHANGE_NAME: "consumer/user/changename",
    SIGN_USER_DATA: "consumer/signInUser/v2",
    APP_MODE: "consumer/appMode",
    GET_CART: "consumer/v4/cart",
    GET_HOME_PAGE: "/consumer/home/v2/rails",
    MOVE_CART_PRODUCTS: "consumer/moveCartProducts",
    POS_LINK: "/api/pos/link_outlet",
};

/**
 * Enum representing different Dynamic Endpoints.
 * @readonly
 * @enum {string}
 */
const DYNAMIC_ENDPOINT = {
    getPaymentOptions: (outletId) =>
        `account/paymentoptions?outletId=${outletId}`,
    getCheckout: (checkoutParams) =>
        `consumer/v3/checkout?CheckoutParams=${checkoutParams}`,
    placeOrder: (checkoutParams) =>
        `order/placeorder?CheckoutParams=${checkoutParams}`,
    getOrders: (pageNumber, pageSize, filter) =>
        `consumer/order/history?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter}`,
    getMoveCartProducts: (appMode) =>
        `consumer/moveCartProducts?appMode=${appMode}`,
};

// export endpoints
export { STATIC_ENDPOINT, DYNAMIC_ENDPOINT };

// TODO: To be moved to enums gradually
export default {
    // payments & place order
    getPaymentAccessCode: (type, showPaymentMethodsMessageHinting) =>
        `user/accesscode?ordertype=${type}&showPaymentMethodsMessageHinting=${showPaymentMethodsMessageHinting}`,
    getPaymentHash: "payment/paymenthash",
    paymentKitIframeSource: "https://www.zomato.com/zpaykit/init",
    updatePaymentStatus: (paymentGUID) =>
        `api/v3/payment/${paymentGUID}/updatePaymentStatus`,
    makePendingPayment: "payment/makependingpayment",
    getPaymentGUID: "api/payment/v2",

    // onboarding
    getDesignations: "consumer/onboarding/designations",

    getCities: "consumer/cities",
    validatePinCode: (cityId, pinCode) =>
        `api/buyeraccount/checkpincodeisserviceable?cityId=${cityId}&zipCode=${pinCode}`, // golang
    serviceablePinCode: (cityId, pinCode) =>
        `/api/consumer/serviceability?cityId=${cityId}&zipCode=${pinCode}`,
    registerStepOne: "consumer/onboarding/saleslead",
    updateRegStepOne: (salesLeadId) =>
        `consumer/onboarding/saleslead${salesLeadId ? `/${salesLeadId}` : ""}`,
    registerStepTwo: "consumer/onboarding/documents",
    registerVerifyOtp: "api/usersignup/verifyotp", // golang
    registerAllSteps: "consumer/onboarding/complete",
    checkGst: (gst) => `api/buyeraccount/registration/checkgstin?gstin=${gst}`, // golang
    validatePhoneNumber: "consumer/onboarding/validateField",
    uploadOnboardDocument: "consumer/onboarding/verification",
    referralCode: (code) =>
        `api/buyeraccount/registration/checkreferralcode?referralCode=${code}`,
    getBonus: (referralCode = "none", cityId) =>
        `consumer/referral?type=${referralCode}&cityId=${cityId}`,
    getOtp: (phoneNumber) =>
        `api/usersignup/otp?userPhoneNumber=${phoneNumber}`, // golang
    getOutlets: "consumer/outlets",
    downloadExcelStatement: "consumer/statement/download/excel",
    getPDFStatementURL: "consumer/statement/download/pdf",
    emailStatement: "consumer/statement/email",
    v2_overDue: (source, categoryId, subCategoryId) =>
        `consumer/outlet/status?source=${source}` +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (subCategoryId ? `&subCategoryId=${subCategoryId}` : ``),

    getCredit: "api/creditmanagement/creditoutletdetail",
    getUserDeviceVerification: "consumer/user_device_verification",
    getEntityOwners: (searchParam) =>
        `consumer/entityOwners?searchParam=${searchParam}`,
    sendDeviceVerificationOtp: (userId) =>
        `consumer/verification/otp?requestUserId=${userId}`,
    verifyDeviceVerificationOtp: `consumer/verification/otp`,

    // user management
    switchOutlet: "consumer/switchOutlet",
    verifyOtp: "consumer/editprofile/verifyotp",
    changeEmail: "consumer/user/emailupdate",
    changePhone: "consumer/user/phonenumber/update",
    changePassword: "consumer/password/resetPasswordFromWebProfile",

    resendEmail: "consumer/user/sendVerificationEmail",
    acceptTnC: `api/v2/buyeraccount/registration/signtnc`,
    getUserOutletMapping: (outletId) =>
        `consumer/outlet/${outletId}/getUsersMapped`,
    modifyUserOutletMapping: `/consumer/useroutletmapping`,
    getUsersForOutletMapping: (outletId, searchParam) =>
        `consumer/outlet/${outletId}/useroutletmapping?searchParam=${searchParam}`,

    // notifications
    getNotifications: (pageNumber) => `notifications?pageNumber=${pageNumber}`,
    markNotificationsRead: "notifications/markRead",
    getNotificationsStatus: "notifications/status",
    notifyOutOfStock: "consumer/product/notify",

    // referral
    sendReferalEmailInvite: "api/user/referralemailinvites",
    getReferral: (outletName, outletId) =>
        `consumer/referral-details?outletName=${outletName}&outletId=${outletId}`,

    // search and catalog
    getSearchSuggestions: (query, outletId, esV2Flag) =>
        `consumer/v2/autoSuggest?query=${query}&outletId=${outletId}&fetchThroughV2=${esV2Flag}`,
    getReqSearchSuggestions: (query, esV2Flag) =>
        `consumer/v2/autoSuggest?query=${query}&fetchThroughV2=${esV2Flag}&source=PRODUCT_REQUEST`,
    getProducts: ({
        query = "",
        outletId = "",
        pageNo,
        categoryIds = "",
        productIds = "",
        sortBy = "",
        sortType = "",
        onOffer = "",
        productNumbers = "",
        referenceType = "",
        referenceId = "",
        esV2Flag = false,
        searchDebugFlag = false,
        otherParams = "",
    }) =>
        `consumer/v2/search?query=${query}&outletId=${outletId}&pageNo=${pageNo}&categoryIds=${categoryIds}&productIds=${productIds}&sortBy=${sortBy}&sortType=${sortType}&onOffer=${onOffer}&productNumbers=${productNumbers}&referenceType=${referenceType}&referenceId=${referenceId}&fetchThroughV2=${esV2Flag}&searchDebugFlag=${searchDebugFlag}&${otherParams}`,
    getProductsWithQuery: (
        query,
        esV2Flag = false,
        debug = false,
        onlyInStock = false
    ) =>
        `consumer/v2/search${query}&fetchThroughV2=${esV2Flag}&searchDebugFlag=${debug}&onlyInStock=${onlyInStock}`,
    getFilters: (query, esV2Flag = false, debug = false, onlyInStock = false) =>
        `consumer/filters${query}&fetchThroughV2=${esV2Flag}&searchDebugFlag=${debug}&onlyInStock=${onlyInStock}`,
    v2_getFilters: (
        query,
        esV2Flag = false,
        debug = false,
        onlyInStock = false
    ) =>
        `${query}&fetchThroughV2=${esV2Flag}&searchDebugFlag=${debug}&onlyInStock=${onlyInStock}`,
    v2_getCategoriesAndSubCategories: (
        accountId,
        visibleOnly,
        activeOnly,
        outletCityId,
        esV2Flag
    ) =>
        `consumer/categories?accountId=${accountId}&visibleOnly=${visibleOnly}&activeOnly=${activeOnly}&warehouseCityId=${outletCityId}&fetchThroughV2=${esV2Flag}`,
    v3_getCategoriesAndSubCategories: () => "/consumer/v2/categories",

    getEntitySubCategories: (query) => `/consumer/entities${query}`,

    // cart
    getCart: (outletId, esV2Flag, postbackParams) =>
        `consumer/v3/cart?outletId=${outletId}&fetchThroughV2=${esV2Flag}&PostbackParams=${postbackParams}`,
    addUpdateDeleteCart: (
        esV2Flag,
        fromDetailPage,
        postbackParams,
        queryString
    ) =>
        `consumer/v3/cart?fetchThroughV2=${esV2Flag}&fromDetailPage=${fromDetailPage}&PostbackParams=${postbackParams}&SearchQuery=${queryString}`,

    // auth
    signIn: "api/registration/signin",
    forgotPasswordEnterPhone: (phoneNumber, source) =>
        `api/user/otpsms?isForgotPassword=true&userPhoneNumber=${phoneNumber}&source=${source}`,
    forgotPasswordVerifyOtp: "api/registration/forgotPasswordResetWeb",
    signOut: "consumer/signout",
    verifyEmail: "consumer/user/verifyEmail",

    // orders
    getOrder: (orderID, type) =>
        `consumer/order/history/details?${
            type === "number" ? "orderNumber" : "orderId"
        }=${orderID}`,
    reOrder: "consumer/orders/reorder",
    downloadFile: (filePath, fileName) =>
        `api/download?filepath=${filePath}&filename=${fileName}`,
    getCreditNote: (creditNoteNumber) =>
        `order/creditnote?creditNoteNumber=${creditNoteNumber}`,
    addOrderRating: "consumer/rating",

    // consumer order issue image upload
    consumerOrderIssueImageUpload: "/consumer/issue/images",

    // consumer order issue ticket
    consumerOrderIssueTicket: "consumer/ticket/productIssues",

    // others
    testimonials: `consumer/testimonials`,
    v2_commercialBanner: (outletCityId) =>
        `consumer/banners/v2?cityId=${outletCityId}`,
    getConfig: "/consumer/config",
    validateLocation: "/consumer/outlet_location/validate",
    updateLocation: "/consumer/outlet_location",
    getHappyOtp: "/consumer/zonboarding/otp",
    onboardZomatoMerchant: "consumer/zonboarding/merchant",
    zomatoDocumentUplaod: "/consumer/zonboarding/document",
    zomatoVerifyPhone: (params = {}) =>
        `/consumer/zonboarding/checkUserPhone?phone=${params.phoneNumber}&sessionId=${params.sessionId}&merchantId=${params.merchantId}`,
    getMerchantDataFromZomato: `/consumer/zonboarding/merchant`,
    zomatoValidatePan: "/consumer/onboarding/validateField",
    refundToWallet: `/consumer/refund_source`,
    updateWhatsappNotificationStatus: `/consumer/user/updateWhatsappNotificationStatus`,
    trackOneClickSteps: "/consumer/zonboarding/step",
    getPopup: (source = "") => `/consumer/popup?source=${source}`,
    pastOrders: (esV2Flag) =>
        `/consumer/orders/reorder/rail?fetchThroughV2=${esV2Flag}`,
    shareAppLink: "/consumer/applink",
    walletTransactions: (pageNumber, pageSize, filter) =>
        `/account/wallet/transactions?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter}`,
    walletRecharge: "/accounts/walletrecharge",
    getRewards: "/consumer/loyalty/rewards",
    updatePayLater: "consumer/paylater/optin",
    track: "/consumer/tracking",
    openTrack: "/consumer/openTracking",
    getConsumerRail: (source, esV2Flag) =>
        `/consumer/v2/rail?source=${source}&fetchThroughV2=${esV2Flag}`,
    autoSuggestHistory: `/consumer/autoSuggest/history`,
    clearHistory: `/consumer/autoSuggest/history`,
    setDeliverySlot: `/consumer/onboarding/deliveryslot`,
    setPinCode: `/consumer/onboarding/pincode`,
    semiServiceable: `/consumer/v2/semiServiceableSearch`,
    getFAQs: `/consumer/faqs`,
    getFAQAnswer: (id) => `consumer/faq?id=${id}`,
    accounts: `/consumer/accounts`,
    getAerobars: `/consumer/aerobars`,
    sideMenu: "consumer/v2/sidemenu",
    getAcquisitionRail: (client) => `consumer/reward/rail?client=${client}`,
    getDownloadDaApp: (appType, appVersion) =>
        `/downloads/apk?appType=${appType}&appVersion=${appVersion}`,
    getPaymentNudges: (source) => `consumer/nudges?source=${source}`,
    npsRating: "/consumer/npsrating",
    npsFeedbackRating: "/consumer/nps",
    requestProduct: "/consumer/request/product",
    getHomePage: "/consumer/home/rails",
    getProductDescMeta: (productId, source) =>
        `/consumer/productDetail?source=${source}&productId=${productId}`,
    updateAccount: "/consumer/user/profile",
    updateOutlet: "/consumer/outletDetails",
    invoiceDetails: "/consumer/viewInvoice",
    autoOnboardingZomato: "/consumer/zonboarding/auto",
    getDeliveryTimeSlots: (cityId, outletId, source) =>
        `/consumer/onboarding/deliveryTimeSlots?cityId=${cityId}` +
        (outletId ? `&outletId=${outletId}` : ``) +
        (source ? `&source=${source}` : ``),
    updateCheckout: "/consumer/checkout",
    zomatoGetStatus: "/consumer/zonboarding/getStatus",
    consumerPreferences: "/consumer/preferences",
    communications: (source, categoryId, subCategoryId) =>
        `/consumer/communications?source=${source}` +
        (categoryId ? `&categoryId=${categoryId}` : ``) +
        (subCategoryId ? `&subCategoryId=${subCategoryId}` : ``),
    getMilestoneDetails: (state, type) =>
        `/consumer/milestoneDetails?state=${state}&type=${type}`,
    getCreditTransactions: (startDate, endDate, PageNo) =>
        `account/creditTransactions?StartDate=${startDate}&EndDate=${endDate}&PageNo=${PageNo}`,
    getCreditTransactionDetails: (txnId) =>
        `account/creditTransactions/details?transactionId=${txnId}`,
    getCreditPayments: `account/creditPayments/details`,
    getCreditPaymentsV2: (
        amount,
        index,
        isEdited,
        paymentInstrumentId,
        paymentInstrumentType
    ) =>
        `account/creditPayments/v2/details?amount=${amount}&paymentInstrumentId=${paymentInstrumentId}&index=${index}&isEdited=${isEdited}&paymentInstrumentType=${paymentInstrumentType}`,
    makeCreditPayment: `account/creditPayment`,
    getCreditOnboarding: "consumer/creditOnboard",
    getCreditPlans: "consumer/creditPlans",
    getConsumerOTP: (source, userPhoneNumber) =>
        `/consumer/otp?source=${source}&userPhoneNumber=${userPhoneNumber}`,
    verifyConsumerOTP: `/consumer/otp`,
    getMaskedNumber: (orderNumber, phoneNumber) =>
        `consumer/maskedNumber?orderNumber=${orderNumber}&phoneNumber=${phoneNumber}`,
    getChat: (leaderId) =>
        `/public/api/scm_app/wms/power_ranger/chat?group_leader_id=${leaderId}`,
    saveChat: `/public/api/scm_app/wms/power_ranger/chat`,
    getGroups: (user_id, res_id) =>
        `/public/api/scm_app/wms/power_rangers/groups?user_id=${user_id}&res_id=${res_id}`,
    createGroup: `/public/api/scm_app/wms/power_rangers/groups`,
    createGroupRequest: `/public/api/scm_app/wms/power_rangers/group_requests`,
    getValidOutlets: (source, outletKeyword) =>
        `consumer/accounts?source=${source}` +
        (outletKeyword ? `&outletKeyword=${outletKeyword}` : ""),
    validateCoupon: (coupon_code, account_id) =>
        `/consumer/validateReactivationCode?coupon_code=${coupon_code}&account_id=${account_id}`,
    claimCoupon: "/consumer/claimReactivationCoupon",
    getPaymentInfo: (outletId, paymentMethodId, paymentMethodType) =>
        `/account/paymentinfo?outletId=${outletId}&PaymentMethodId=${paymentMethodId}&PaymentMethodType=${paymentMethodType}`,
    getCartDiscounts: (sourcePage) =>
        `/consumer/cartDiscounts?sourcePage=${sourcePage}`,
    getCartDiscountsV2: (sourcePage) =>
        `/consumer/category/bottomBar?sourcePage=${sourcePage}`,
    addOrUpdateOutletPhoneNumber: `/consumer/outlet/phone`,
    deleteContact: `/consumer/outlet/phone`,
    consumerRefundJourney: (params) => `consumer/refund/journey?${params}`,
    getAddressDetailsUsingLatLong: (lat, long, actionSource) =>
        `/consumer/addressDetails?latitude=${lat}&longitude=${long}&actionSource=${actionSource}`,
    getSearchLocations: (searchQuery) =>
        `/consumer/searchLocations?searchQuery=${searchQuery}`,
    updateOutletLocation: "/consumer/outletLocation",
    checkOutletLocation: "/consumer/outletLocation/details",
    getHomeCategoryRails: (pageNo) =>
        `/consumer/home/rails/categories?pageNo=${pageNo}`,
    cancelOrder: "/consumer/order/cancel",
    changeDeliverySlot: "consumer/slot",
    chatBot: (payloadToken, accessToken) =>
        `https://zomato.com/support/user/#/issue?token=${payloadToken}&unified-support-access-token=${accessToken}`,
    generatePayloadToken: "/consumer/chatbot/payloadToken",
    generateAccessToken: "/consumer/chatbot/accessToken",
    verifyUser: (phoneNo) => `/api/verifyUser?phoneNumber=${phoneNo}`,
    verifyOtp: (phoneNo, OTP, otpType) =>
        `api/user/verifyotp?phoneNumber=${phoneNo}&otp=${OTP}&otpType=${otpType}`,
    getDeliveryTimeSlotsForPackage: (cityId, outletId, source, packageId) =>
        `/consumer/deliveryTimeSlots?cityId=${cityId}` +
        (outletId ? `&outletId=${outletId}` : ``) +
        (source ? `&source=${source}` : ``) +
        `&packageId=${packageId}`,
    getOnboardingStatus: "/consumer/onboardingStatus",
    personalDetails: "/consumer/personalDetails",
    restaurantDetails: "/consumer/outletLocation",
    documentVerificationViewDetails: (viewType) =>
        `/consumer/documentVerificationViewDetails?viewType=${viewType}`,
    uploadDocuments: "/consumer/uploadDocumentAndStartTextExtraction",
    pollDocuments: "/consumer/verifyAndUpdateDocumentOCR",
    completeOnboarding: "/consumer/completeOnboarding",
    getSOAOptions: "/consumer/SOAOptions",
    getSOAFilePath: "/consumer/soaFilePath",
    updateIngredientList: "/consumer/ingredientList",
    bottomTabs: "/consumer/bottomtabs",
    getOwnersDashboard: (
        entityType,
        startDate,
        durationType,
        entityIdsString
    ) =>
        `/consumer/ownersdashboard?entityType=${entityType}` +
        `&startDate=${startDate}` +
        `&durationType=${durationType}` +
        `&entityIds=${entityIdsString}`,
    submitFeedback: "/consumer/feedback",
    getOwnersDashboardFilePath: (
        entityType,
        startDate,
        durationType,
        entityIdsString
    ) =>
        `/consumer/ownersDashboardReport?entityType=${entityType}` +
        `&startDate=${startDate}` +
        `&durationType=${durationType}` +
        `&entityIds=${entityIdsString}`,
    getCorporateAnnouncements: "consumer/corporateAnnouncements",
    createServiceOrder: "/account/creditPayment/serviceOrder",
    orderPaymentInfo: "/account/orderPaymentsInfo",
    getOwnerDetails: "/consumer/ownerDetails",
    addUserPhoneNumberAndReonboard: "/consumer/user/profile",
    walletRechargeData: (paymentMethodId, paymentMethodType, amount) =>
        `/account/wallet?paymentMethodId=${paymentMethodId}&paymentMethodType=${paymentMethodType}&amount=${amount}`,
    getSettingsConfig: "/consumer/settingsConfig",
    updateTaxToggle: "/consumer/tax/toggle",
    adjustPgCharge: "/order/adjustPgCharge",
    paymentDetails: (identifier, paymentMethodId, paymentMethodType) => `/consumer/payment/details?identifier=${identifier}` + (paymentMethodId ? `&paymentMethodId=${paymentMethodId}` : ``) + (paymentMethodType ? `&paymentMethodType=${paymentMethodType}` : ``),
    accquireLock: `/consumer/payment/checkAndAcquireLockOnGuid`,
};
