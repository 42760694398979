import actionTypes from "../actionTypes";
import queryString from "query-string";
import _cloneDeep from "lodash-es/cloneDeep";
import moment from "moment";

import {APP_MODE_POPUP_DATA} from "../../utils/constants/modalBody";

const source = queryString.parse(window.location.search).source;

if (!window.localStorage.getItem("isHomeTourDone")) {
  window.localStorage.setItem("isHomeTourDone", false);
}

if (!window.localStorage.getItem("postbackParams")) {
  window.localStorage.setItem("postbackParams", "");
}

const initialState = {
  data: null,
  message: null,
  acceptanceData: null,
  cities: [],
  outlets: [],
  deliveryTimeSlots: [],
  cityDeliveryTimeSlots: {},
  designations: [],
  referralData: null,
  commercialBanners: [],
  exigencyBanners: [],
  config: null,
  promotionData: null,
  showLocationModal:false,
  locationModalData:null,
  notifications: {
    list: [],
    nextPage: 1,
    hasMore: true,
    unseenCount: 0,
  },
  showAppInstallPrompt: ((!source || source !== "app") && !window.location.href.includes("zomato/onboarding") && window.innerWidth < 992),
  tour: {
    home: window.localStorage.getItem("isHomeTourDone") === "false" ? 1 : false,
    listing: 1,
    cart: 1
  },
  payLaterModalMessage: {
    optIn: {
      imageURL: "",
      backgroundImageURL: "",
      backgroundAnimationURL: "",
      heading: "",
      text: ""
    }
  },
  consumerRail: null,
  homeData: {
    exigencyBanners: [],
    rails: [],
    invalidStateData: {},
  },
  showChatBotConfig : {
    showChatBot: false,
    isSummaryPage : false,
    isSideMenu : false,
    renderChatBotEverywhere : false,
  },
  showSOAModal: false,
  soaOptions: {},
  soaFilePath: {},
  bottomTabs: {},
  userOutletMapping : [],
  IsOutletOwner: false,
  usersForOutletMapping: [],
  addPhoneNumberAndReonboardUser: {
    "Token": "",
    "OutletId": "",
  },
  appModeModalData: APP_MODE_POPUP_DATA,
  posData : {
    loading : false,
    data : {},
  },
  posUserData: {
    loading : false,
    data: {},
  },
};

function userReducer(state = initialState, action) {
  const payload = action.payload;
  switch (action.type) {
    case actionTypes.HOME_TOUR_BEGIN:
      document.getElementsByTagName("body")[0].style.overflow = "auto";
      return {
        ...state,
        tour: {
          ...state.tour,
          home: action.step,
        },
      };

    case actionTypes.TOGGLE_SEARCH:
      return {
        ...state,
        toggleSearch: payload.toggleSearch,
      };

    case actionTypes.CLEAR_AUTO_SUGGEST_HISTORY:
      return {
        ...state,
        isAutoSuggestHistoryClear: true,
      };
    case actionTypes.NOTIFICATIONS_GET_STATUS_DONE:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          unseenCount: payload.unseenCount,
        },
      };

    case actionTypes.GET_REWARDS_DONE:
      return {
        ...state,
        rewards: action.rewards,
      };

    case actionTypes.NOTIFICATIONS_GET_BEGIN:
      if (payload.isFirstTime) {
        return {
          ...state,
          notifications: {
            ...state.notifications,
            list: [],
          },
        };
      } else {
        return state;
      }

    case actionTypes.NOTIFICATIONS_GET_DONE: {
      const list = payload.isFirstTime
        ? payload.notifications
        : [...state.notifications.list, ...payload.notifications];
      return {
        ...state,
        notifications: {
          ...state.notifications,
          list: list,
          nextPage: payload.isFirstTime ? 2 : state.notifications.nextPage + 1,
          hasMore: list.length < payload.totalCount,
        },
      };
    }

    case actionTypes.RESET_NOTIFICATION_COUNT: {
      return {
        ...state,
        notifications: {
          ...state.notifications,
          unseenCount: 0,
        },
      };
    }

    case actionTypes.REGISTER_STEP_THREE_DONE:
      return {
        ...state,
        promotionData: payload.promotionData,
      };

    case actionTypes.CONFIG_GET_DONE:
      return {
        ...state,
        config: payload,
      };

    case actionTypes.USER_GET_DATA_DONE:
      return {
        ...state,
        data: { 
          ...state.data, 
          ...action.data , 
          cartProductsCount: action?.data?.CombinedCartCount, 
          combinedCartCheckout : {
          isEnabled : false,
          modal: {
            isVisible : false,
          }
        }},
        showLocationModal: action.showLocationModal,
        locationModalData: {
          ...state.locationModalData,
          ...action.locationModalData,
        },
      };

    case actionTypes.CART_DELETE_DONE:
      return {
        ...state,
        data: {
          ...state.data,
          cartProductsCount: action.payload.cartCount,
        },
        CartDiscount: action.payload.CartDiscount,
      };

    case actionTypes.CART_UPDATE_DONE:
      return {
        ...state,
        data: {
          ...state.data,
          cartProductsCount: action.cartCount,
        },
        CartDiscount: action.CartDiscount,
      };

    case actionTypes.CATALOG_SEARCH_PRODUCTS_FOR_BUYER_DONE: {
      if (action.updatedCartCount) {
        return {
          ...state,
          data: {
            ...state.data,
            cartProductsCount: action.updatedCartCount,
          },
        };
      }
      return { ...state };
    }

    case actionTypes.CART_ADD_DONE:
      const customConsumerRail = _cloneDeep(state.consumerRail);
      if (action.productType === "forgetProduct") {
        customConsumerRail.Rails[0].Products =
          customConsumerRail.Rails[0].Products.filter((ele) => {
            if (ele.Id !== action.product.Id) {
              return true;
            }
            return false;
          });
      }
      return {
        ...state,
        data: {
          ...state.data,
          cartProductsCount: state.data.cartProductsCount + 1,
        },
        consumerRail: customConsumerRail,
        CartDiscount: action.CartDiscount,
      };

    case actionTypes.SEND_REFERRAL_EMAIL_INVITE_DONE:
      return {
        ...state,
        message: action.data,
      };

    case actionTypes.ACCEPT_TNC_DONE:
      return {
        ...state,
        acceptanceData: action.data,
      };

    case actionTypes.CART_GET_DONE:
      return {
        ...state,
        data: {
          ...state.data,
          cartProductsCount: action.cartCount ? action.cartCount : 0,
        },
        CartDiscount: action.CartDiscount,
      };

    case actionTypes.CART_CLEAR_DONE:
      return {
        ...state,
        data: {
          ...state.data,
          cartProductsCount: 0,
        },
      };

    case actionTypes.GET_OVERDUE_DONE:
      return {
        ...state,
        overdue: action.data,
      };
    case actionTypes.GET_USER_DEVICE_VERIFICATION_DONE:
      return {
        ...state,
        user_device_verification: action.data,
      };
    case actionTypes.GET_ENTITY_OWNERS_DONE:
      return {
        ...state,
        entity_owners: action.data,
      };
      
    case actionTypes.GET_OWNER_DETAILS_DONE:
      return {
        ...state,
        owner_details: action.data
      }

    case actionTypes.POLL_DOCUMENT_DETAILS_DONE:
      action.data.lastCallMade = moment().format("HH:mm:ss")
      return {
        ...state,
        poll_document_details: action.data
      }
    
    case actionTypes.DOCUMENT_VERIFICATION_DETAILS_DONE:
      let heading = action?.data?.Heading?.length>0 ? action?.data?.Heading : state.document_verification_details.Heading;
      let subHeading = action?.data?.SubHeading?.length>0 ? action?.data?.SubHeading : state.document_verification_details.SubHeading;
      action.data.Heading = heading
      action.data.SubHeading = subHeading
      if (action?.data?.DocumentDetails?.Data?.DateOfRegistration?.length > 0){
        action.data.DocumentDetails.Data.DateOfRegistration =  moment(action.data.DocumentDetails.Data.DateOfRegistration, "DD/MM/YYYY").toDate()
      }
      return {
        ...state,
        document_verification_details:action.data
      }
    
    case actionTypes.UPLOAD_DOCUMENT_DETAILS_DONE:
      return {
        ...state,
        upload_document_details: action.data
      }
    
    
    case actionTypes.DOCUMENT_NUMBER_VERIFICATION_DETAILS_DONE:
      return {
        ...state,
        document_number_verification_details: action.data
      }
    
    case actionTypes.GET_COMMERCIAL_BANNER_DONE:
      return {
        ...state,
        commercialBanners: action.data,
        exigencyBanners: action.exigencyBanners,
      };

    case actionTypes.GET_FEATURE_TICKER_DONE:
      return {
        ...state,
        featureTickers: action.data,
      };
    case actionTypes.GET_COLLABORATIVE_BANNERS_DONE:
      return {
        ...state,
        collaborativeBanners: action.data,
      };
    case actionTypes.GET_EXIGENCY_BANNERS_DONE:
      return {
        ...state,
        exigencyBannersV2: action.data,
      };
    case actionTypes.TESTIMONIALS_DONE:
      return {
        ...state,
        testimonials: action.testimonials,
      };

    case actionTypes.USER_GET_CITIES_DONE:
      return {
        ...state,
        cities: action.cities,
      };

    case actionTypes.OUTLET_SWITCH_DONE:
      return {
        ...state,
        outletSwitchId: action.outletSwitchId,
      };

    case actionTypes.USER_GET_DELIVERY_TIME_SLOTS_BEGIN:
      return {
        ...state,
        deliveryTimeSlots: [],
      };

    case actionTypes.USER_GET_DELIVERY_TIME_SLOTS_DONE: {
      if (action.storeCityBasedSLots) {
        return {
          ...state,
          cityDeliveryTimeSlots: {
            ...state.cityDeliveryTimeSlots,
            ...action.cityDeliveryTimeSlots,
          },
        };
      }
      return {
        ...state,
        deliveryTimeSlots: action.deliveryTimeSlots,
      };
    }

    case actionTypes.USER_GET_DESIGNATIONS_DONE:
      return {
        ...state,
        designations: action.designations,
      };

    case actionTypes.USER_GET_OUTLETS_BEGIN:
      return {
        ...state,
        accounts: [],
      };

    case actionTypes.USER_GET_OUTLETS_DONE:
      return {
        ...state,
        accounts: action.accounts,
        showSearch: action.showSearch,
      };

    case actionTypes.USER_GET_OUTLETS_ONLY_BEGIN:
      return {
        ...state,
        outlets: [],
      };

    case actionTypes.USER_GET_OUTLETS_ONLY_DONE:
      return {
        ...state,
        outlets: action.outlets,
      };

    case actionTypes.USER_GET_REFERRAL_DONE:
      return {
        ...state,
        referralData: action.data,
      };

    case actionTypes.CLOSE_APP_INSTALL_PROMPT:
      return {
        ...state,
        showAppInstallPrompt: false,
      };
    case actionTypes.GET_POPUP_CHECKOUT_DONE:
    case actionTypes.GET_POPUP_HOME_DONE:
      return {
        ...state,
        popupData: {
          ...action.data,
          isDismissed: false,
        },
      };
    case actionTypes.GET_POPUP_DONE:
      return {
        ...state,
        popupData: action.data,
      };

    case actionTypes.GET_PAST_ORDERS_DONE:
      return {
        ...state,
        pastOrders: action.data,
      };

    case actionTypes.GET_APP_LINK_DONE:
      return {
        ...state,
        appLinks: action.applinks,
      };

    case actionTypes.PAY_LATER_UPDATE_DONE:
      return {
        ...state,
        payLaterModalMessage: {
          ...state.payLaterModalMessage,
          optIn: {
            ...state.payLaterModalMessage.optIn,
            imageURL: payload.imageURL,
            backgroundImageURL: payload.backgroundImageURL,
            backgroundAnimationURL: payload.backgroundAnimationURL,
            heading: payload.heading,
            text: payload.text,
          },
        },
      };

    case actionTypes.SEMI_SERVICEABLE_DONE:
      return {
        ...state,
        semiServiceableData: {
          ...state.semiServiceableData,
          data: action.data,
          hasMore: action.hasNextPage,
          nextPage: action.isFirstTime
            ? 2
            : state.semiServiceableData.nextPage + 1,
          isFirstTime: action.isFirstTime,
        },
      };

    case actionTypes.GET_CONSUMER_RAIL_DONE:
      return {
        ...state,
        consumerRail: action.consumerRail,
      };

    case actionTypes.GET_FAQ_DONE:
      return {
        ...state,
        FAQs: action.data,
      };

    case actionTypes.GET_FAQ_ANSWER_DONE:
      return {
        ...state,
        FAQsAnswer: action.data,
      };

    case actionTypes.SET_FAQ:
      return {
        ...state,
        FAQsQuestion: action.data,
      };

    case actionTypes.RESET_FAQ:
      return {
        ...state,
        FAQsQuestion: null,
        FAQsAnswer: null,
      };

    case actionTypes.GET_AUTH_OPTIONS_DONE:
      return {
        ...state,
        authOptions: action.data,
      };

    case actionTypes.ADD_NPS_RATING_DONE:
      return {
        ...state,
        reasonData: action.data.Reasons,
      };

    case actionTypes.GET_HOME_DONE:
      return {
        ...state,
        homeData: {
          ...state.homeData,
          exigencyBanners: action.payload.ExigencyBanner,
          rails: action.payload.Sections,
          invalidStateData: action.payload.InvalidStateData,
          header: action.payload.Header,
        },
      };

    case actionTypes.MILESTONE_DETAILS_DONE:
      return {
        ...state,
        milestoneData: action.payload,
      };

    case actionTypes.MILESTONE_HISTORICAL_DETAILS_DONE:
      return {
        ...state,
        milestoneHistoricalData: action.payload,
      };

    case actionTypes.GET_ZOMATO_STATUS_DONE:
      return {
        ...state,
        zomatoStatus: action.payload,
      };

    case actionTypes.UPDATE_USER_PREFERENCE_DONE:
      let preferenceState = {
        ...state,
        userPreference: action.payload,
        data: {
          ...state.data,
          preferences: {
            ...state.data.preferences,
          },
        },
      };
      if (action.setInRedux) {
        preferenceState.data.preferences[action.preferenceType] =
          action.payload[action.preferenceType];
      }

      return preferenceState;

    case actionTypes.GET_COMMUNICATIONS_DONE:
      return {
        ...state,
        commData: action.payload,
        overdue: action.payload,
      };

    case actionTypes.GET_COMMUNICATIONS_INIT:
      return {
        ...state,
        commData: action.payload,
        overdue: action.payload,
      };

    case actionTypes.TOGGLE_DA_MODAL:
      return {
        ...state,
        viewDAModal: action.payload,
        deliveryData: action.deliveryData,
      };

    case actionTypes.GET_CART_DISCOUNT_DONE:
      return {
        ...state,
        CartDiscount: action.CartDiscount,
      };

    case actionTypes.SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: action.showNotification,
      };

    case actionTypes.SHOW_HEADER_DELIVERY_NUDGE:
      return {
        ...state,
        headerDeliveryNudge: action.headerDeliveryNudge,
      };

    case actionTypes.SHOW_OUTLET_SWITCH_MODAL:
      return {
        ...state,
        outletSwitch: action.outletSwitch,
      };
    case actionTypes.GET_SEARCH_BAR_VERTICALS:
      return {
        ...state,
        searchBar: {
          data: action.data,
        },
      };
    case actionTypes.GET_ADDRESS_DETAILS_DONE:
      return {
        ...state,
        adressLocationDetails: action.payload,
      };

    case actionTypes.GET_ADDRESS_DETAILS_INIT:
      return {
        ...state,
        adressLocationDetails: null,
      };

    case actionTypes.GET_SEARCH_LOCATIONS_DONE:
      return {
        ...state,
        searchLocations: action.payload,
      };
    case actionTypes.UPDATE_OUTLET_LOCATION_DONE:
      return {
        ...state,
        updateLocation: action.payload,
      };
    case actionTypes.CHECK_OUTLET_LOCATION_DONE:
      return {
        ...state,
        checkOutlet: action.payload,
      };
    case actionTypes.GET_OFFER_DETAILS_DONE:
      return {
        ...state,
        offerDetailsData: action.payload,
      };
    case actionTypes.GET_HOME_PAGE_CATEGORY_RAILS_INIT:
      return {
        ...state,
        homePageCategoryRails: {},
      };
    case actionTypes.GET_HOME_PAGE_CATEGORY_RAILS_DONE:
      return {
        ...state,
        homePageCategoryRails: {
          ...action.payload,
          Heading: state.homePageCategoryRails?.Heading
            ? state.homePageCategoryRails?.Heading
            : action.payload.Heading,
          Rails: state.homePageCategoryRails?.Rails?.length
            ? state.homePageCategoryRails?.Rails.concat(action.payload.Rails)
            : action.payload.Rails,
        },
      };
    case actionTypes.GET_SEARCH_LOCATIONS_INIT:
      return {
        ...state,
        searchLocations: [],
      };
    case actionTypes.CHANGE_DELIVERY_SLOT_DONE:
      return {
        ...state,
        changeDeliverySlot: action.payload,
      };
    case actionTypes.GENERATE_PAYLOAD_TOKEN_DONE:
      return {
        ...state,
        payloadToken: action.payload,
      };
    case actionTypes.GENERATE_ACCESS_TOKEN_DONE:
      return {
        ...state,
        accessToken: action.payload,
      };
    case actionTypes.VERIFY_USER_DONE:
      return {
        ...state,
        verifyUserData: action.payload,
      };
    case actionTypes.VERIFY_USER_FAIL:
      return {
        ...state,
        verifyUserData: {
          ...state.verifyUserData,
          errorData: action.errorData,
        },
      };
    case actionTypes.VERIFY_USER_OTP_DONE:
      return {
        ...state,
        verifyUserOTPData: action.payload,
      };
    case actionTypes.FORGOT_PASSWORD_ENTER_PHONE_FAIL:
    case actionTypes.VERIFY_USER_OTP_FAIL:
      return {
        ...state,
        verifyUserOTPData: {
          ...state.verifyUserOTPData,
          errorData: action.errorData,
        },
      };
    case actionTypes.VERIFY_USER_INIT:
      return {
        ...state,
        verifyUserData: {},
      };
    case actionTypes.VERIFY_USER_OTP_INIT:
      return {
        ...state,
        verifyUserOTPData: {},
      };
    case actionTypes.SHOW_CHATBOT:
      return {
        ...state,
        showChatBotConfig: {
          ...state.showChatBotConfig,
          showChatBot: action.payload.showChatBot,
          isSummaryPage: action.payload.isSummaryPage,
          renderChatBotEverywhere: action.payload.renderChatBotEverywhere,
        },
      };
    case actionTypes.TOGGLE_SOA_MODAL:
      return {
        ...state,
        showSOAModal: action.payload.showSOAModal,
      };
    case actionTypes.GET_SOA_OPTIONS_DONE:
      return {
        ...state,
        soaOptions: action.payload,
      };
    case actionTypes.GET_SOA_FILE_PATH_DONE:
      return {
        ...state,
        soaFilePath: action.payload,
      };
    case actionTypes.BOTTOM_TABS_DONE:
      return {
        ...state,
        bottomTabs: action.payload,
      };

    case actionTypes.GET_USER_OUTLET_MAPPING_DONE:
      return {
        ...state,
        userOutletMapping: action.payload?.Users,
        IsOutletOwner: action.payload?.IsOwnerUser,
      };

    case actionTypes.GET_USERS_FOR_OUTLET_MAPPING_DONE:
      return {
        ...state,
        usersForOutletMapping: payload?.UsersData,
      };

    case actionTypes.GET_USERS_FOR_OUTLET_MAPPING_INIT:
      return {
        ...state,
        usersForOutletMapping: [],
      };
    case actionTypes.ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP_DONE:
      return {
        ...state,
        addPhoneNumberAndReonboardUser: {
          ...state.addPhoneNumberAndReonboardUser,
          Token: action.payload.Token,
        },
      };
    case actionTypes.SET_APP_MODE:
      return {
        ...state,
        appModeModalData: action.payload?.popupData,
      };
    case actionTypes.SET_APP_MODE_INFO:
      return {
        ...state,
        appModeInfo: payload,
      };
    case actionTypes.DISMISS_APP_MODE_POPUP:
      return {
        ...state,
        appModeModalData: {
          ...state.appModeModalData,
          visible: false,
        },
      };
    case actionTypes.COMBINED_CART_CHECKOUT: {
      return {
        ...state,
       data : {
        ...state.data,
        combinedCartCheckout: payload
       }
      }
    }
    case actionTypes.DISMISS_COMBINED_CART_CHECKOUT_POPUP: {
      return {
        ...state,
        data: {
          ...state.data,
          combinedCartCheckout: {
            ...state.data.combinedCartCheckout,
            modal: payload
          }
        }
      }
    }
    case actionTypes.GET_POS_LINK_BEGIN:
      return {
        ...state,
        posData: {
          ...state.posLinkData,
          loading: true,
        },
      };
    case actionTypes.GET_POS_LINK_DONE:
      return {
        ...state,
        posData: {
          ...state.posData,
          loading: false,
          data: payload,
        },
        posUserData: {
          ...state.posUserData,
          data: {
            ...state.posUserData.data,
            images: payload?.images
          },
        },
      };
    case actionTypes.GET_POS_LINK_FAIL:
      return {
        ...state,
        posData: {
          ...state.posData,
          loading: false,
        },
      };
    case actionTypes.SET_POS_LINK_BEGIN:
      return {
        ...state,
        posData: {
          ...state.posData,
          loading: true,
        },
      };
    case actionTypes.SET_POS_LINK_DONE:
      return {
        ...state,
        posData: {
          ...state.posData,
          loading: false,
          data: payload,
        },
      };
    case actionTypes.SET_POS_LINK_FAIL:
      return {
        ...state,
        posData: {
          ...state.posData,
          loading: false,
        },
      };      
      
    default:
      return state;
  }
}

export default userReducer;
