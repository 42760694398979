import React from "react";
import { useState } from "react";
import Modal from "sushiweb/Modal/Modal";
import { MODAL_TYPE_SIGN_UP,STATUS_VERIFIED, MODAL_TYPE_VERIFY_FORGET_PASSWORD_OTP, MODAL_TYPE_VERIFY_FORGET_PASSWORD_PASSWORD_MATCH,GST_NUMBER, MODAL_TYPE_VERIFY_NUMBER, MODAL_TYPE_VERIFY_OTP, MODAL_TYPE_VERIFY_PASSWROD,MODAL_TYPE_SIGN_UP_PASSWORD,MODAL_TYPE_SIGN_UP_OTP,GET_PERSONAL_DETAILS,GET_RES_LOCATION_DETAILS_2,GET_RES_LOCATION_DETAILS,GET_PAN_DETAILS, GET_GST_DETAILS, GET_FSSAI_DETAILS, GET_UPLOAD_DETAILS,COMPLETE_ONBOARDING_SETUP,ADD_LOCATION_SIGN_UP, RESTAURANT_SETUP, LOGOUT, PERSONAL_DETAIL_SUBHEADING, PERSONAL_DETAIL_HEADING, CTA_OKAY, MANUALLY, PERSONAL_DETAIL_NAME, PERSONAL_DETAIL_LABEL, EMAIL, PERSONAL_DETAIL_EMAIL, OUTLET_NAME, RESTAURANT_NAME, ADDRESS, COMPLETE_ADDRESS_LABEL, PINCODE, LABEL_PINCODE, KEY_FLOOR, LABEL_FLOOR, FLOOR_OPTIONS, KEY_LIFT, LABEL_LIFT_AVAILABLE, LIFT_OPTIONS, LANDMARK, NEARBY_LANDMARK, DOCUMENT_TYPE_PAN, PAN_NUMBER, STATUS_VERIFY, PAN_ACTION, LABEL_PAN, LEGAL_NAME, LEGAL_ENTITY_LABEL, FSSAI_NUMBER, FSSAI_ACTION, GST_ACTION, DOCUMENT_PAN, DOCUMENT_FSSAI, ENTER_GST_MANUALLY, LATER_CTA, ROUTE_WITH_QUERY_CART, ROUTE_WITH_QUERY_CHECKOUT, ROUTE_WITH_QUERY_HOME, GEO_LOCATION_ERROR, PERSONAL_DETAIL_OBJECT, RESTAURANT_DETAIL_OBJECT, PAN_DETAIL_OBJECT, FSSAI_DETAIL_OBJECT, GST_DETAIL_OBJECT, UPLOAD_DETAIL_OBJECT, PAN_DETAIL_OBJECT_V2, VERIFY_ACTION_ENDPOINT,
    ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER, ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP, COOKIE_TTL_FOR_WEB } from "../../../utils/constants/others";
import LoginScreens from "../LoginScreens/LoginScreens";
import styles from "./Header.module.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { verifyUser  ,resetVerifyUserData ,verifyUserOtp , resetVerifyUserOTPData, trackingWithoutAuth, completePersonalDetails,completeRestaurantDetails,documentVerificationViewDetails,completeUploadDetails,getOwnerDetails,callCustomEndpoint,uploadDocuments,pollDocuments, 
    addUserPhoneNumberAndReonboard,
    addUserPhoneNumberAndReonboardVerifyOTP} from "../../../redux/dispatchers/userDispatcher";
import { validatePhoneNumber } from "../../../utils/validationUtils";
import { useEffect } from "react";
import stateTypes from "../../../utils/constants/stateTypes";
import { signIn  , forgotPasswordEnterPhone } from "../../../redux/dispatchers/authDispatcher";
import { forgotPasswordVerifyOtp } from "../../../redux/dispatchers/otpDispatcher";
import { deleteCookieForEntity, getCookie, getCookieForEntity, getValidatedPasswordArray, setCookieForEntity } from "../../../utils/helpers";
import Check from "sushiweb/Icons/all/Check";
import { teal } from "sushiweb/tokens/color";
import grey from "sushiweb/tokens/color/grey";
import Image from "../Image/Image";
import Button from "../Button";
import { result, set } from "lodash-es";
import LocationModal from "../location/LocationModal";
import { getAddressDetailsUsingLatLong, getSearchLocations, updateOutletLocation } from "../../../redux/dispatchers/userDispatcher";
import { verifyPanAndLegalEntityName } from "../../../redux/dispatchers/userDispatcher";
import { trackPopUp } from "../../../redux/dispatchers/userDispatcher";
import { getUserData } from "../../../redux/dispatchers/userDispatcher";
import Spinner from "sushiweb/Spinner";
import { RegexEmail } from "../../../utils/regex";
import { HTMLDiv } from "../HTMLDiv";
import SpinnerCircular from "sushiweb/Spinner/SpinnerCircular";
import moment from "moment";

const SignInFlow = (props) => {


    const [phoneNumber , setPhoneNumber] = useState({
        value: "",
        error: ""
    })
    const [password, setPassword] = useState({
        value: "",
        checkValue: "",
        error: ""
    })
    const [otp, setOtp] = useState({
        value: "",
        error: ""
    })

    const [tncStatus,setTncStatus] = useState({
        key:"",
        value:""
    })

    const [receiveWhatsAppNotif,setReceiveWhatsAppNotid] = useState({
        key:"",
        value:""
    })
    
    const [finalStateModal,setFinalStateModal] = useState({Image:"",Text:"",Open:false,CTA:""}) 
    const [globalLoader,setGlobalLoader] = useState(false)
    const [personalDetails,setPersonalDetails]=useState(PERSONAL_DETAIL_OBJECT)
    const [restaurantDetails,setRestaurantDetails] = useState(RESTAURANT_DETAIL_OBJECT)
    const [togglers , setTogglers] = useState({
        currentType : props?.actionType?.length ? props?.actionType:MODAL_TYPE_VERIFY_NUMBER,
        loading: false,
    })

    const getDocumentType = () => {
        switch (togglers.currentType){ 
            case GET_PAN_DETAILS: 
                return DOCUMENT_TYPE_PAN
            default: 
                return ""
        }
    }

    const [documentVerificationViewDetailsData,setDocumentVerificationViewDetailsData] = useState({})
    const [panDetails,setPanDetails] = useState([])
    const [fssaiDetails,setFssaiDetails] = useState([])
    const [gstDetails,setGstDetails] = useState(GST_DETAIL_OBJECT)
    const [entityDetails,setEntityDetails] = useState({})
    const [uploadDetails,setUploadDetails] = useState([])
    const [uploadCTAEnabled,setUploadCTAEnabled] = useState(false)
    const [documentJobs,setDocumentJobs] = useState([])
    const [parentToggler,setParentToggler] = useState("")
    const [latitude,setLatitude] = useState(0)
    const [longitude,setLongitude] = useState(0)
    const [updatedLocation,setUpdatedLocation] = useState({locationUpdated:false,latitude:0,longitude:0})

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [documentArray,setDocumentArray] = useState([])
    const [intervalId,setIntervalId] = useState(0)

    //upload details
    const [bottomMessage,setBottomMessage] = useState({})
    const [onboardingCtaEnabled,setOnboardingCtaEnabled] = useState(false)
    const RESEND_OTP_FLOWS = [MODAL_TYPE_VERIFY_OTP, MODAL_TYPE_VERIFY_FORGET_PASSWORD_OTP, MODAL_TYPE_SIGN_UP_OTP, ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP];

    const setGstValueAndSelectedParam = (index,text,latitude,longitude,actionType) =>{

        if(latitude && longitude && actionType.length){
            setLatitude(latitude)
            setLongitude(longitude)
            props.setActionType(actionType)
            setParentToggler(GET_GST_DETAILS)
        }else{
            setGstDetails([{key:GST_NUMBER,
            value: gstDetails[0].value,
            options:gstDetails[0].options,
            isSelectedIndex:index,
            selectedValue:text,
            label:"",
            error: "",Button:gstDetails[0].Button}])
        }  
    }
    useEffect(()=>{

        if (props?.actionType === GET_PERSONAL_DETAILS ||props?.actionType===GET_RES_LOCATION_DETAILS || props?.actionType === GET_RES_LOCATION_DETAILS_2 || props?.actionType === GET_PAN_DETAILS || props?.actionType === GET_GST_DETAILS || props?.actionType === GET_FSSAI_DETAILS || props?.actionType === GET_UPLOAD_DETAILS || props?.actionType === COMPLETE_ONBOARDING_SETUP || props?.actionType === ADD_LOCATION_SIGN_UP ){  
           
            setTogglers({
                currentType : props?.actionType,
                loading: false, 
            });
           
        }

        if (props?.actionType === GET_GST_DETAILS){
            setGlobalLoader(true)
        }

        if(props?.actionType === ADD_LOCATION_SIGN_UP){
            if(!getCookieForEntity("outletId","posOutletId")){
                props.actions.getUserData();
            }
        }

       
        if (props?.actionType === GET_PERSONAL_DETAILS && props?.getOwnerDetailsStatus?.state === stateTypes.STATE_INIT){
            props.actions.getOwnerDetails();
        }

        if (props?.actionType === GET_PAN_DETAILS && props?.documentVerificationDetailsState?.state === stateTypes.STATE_INIT){

            //setGlobalLoader(true)
            let viewType=DOCUMENT_TYPE_PAN
            props.actions.documentVerificationViewDetails(viewType)
                // ,(data)=>{
                // setGlobalLoader(false)
                // setDocumentVerificationViewDetailsData(data)
            //     let visibleStatus = data?.DocumentDetails?.Data?.LegalEntityName?.length? true:false
            //      setPanDetails([{key:PAN_NUMBER,
            //         value: data?.DocumentDetails?.Data?.Number?.length?data?.DocumentDetails?.Data?.Number:"",
            //         label:LABEL_PAN,
            //         error: "",Button:{loaderVisible:false,text:STATUS_VERIFY,color:'red',action:PAN_ACTION,disabled:true,visible:data?.DocumentDetails?.Data?.Number?.length?false:false,queryKey:PAN_NUMBER}},{key:"legalName",
            //         value: data?.DocumentDetails?.Data?.LegalEntityName?.length?data?.DocumentDetails?.Data?.LegalEntityName:"",
            //         label:"Legal entity name",
            //         error: "",visible:visibleStatus,disabled:true}])
            //         if(data?.DocumentDetails?.Data?.EntityDetails){
            //             setEntityDetails(data?.DocumentDetails?.Data?.EntityDetails)
            //         }
            // },(err)=>{
               
            // }
            //)
        }

        if (props?.actionType === GET_FSSAI_DETAILS && props?.documentVerificationDetailsState?.state === stateTypes.STATE_INIT){
            let viewType="FSSAI"
            props.actions.documentVerificationViewDetails(viewType)
        }

        if (props?.actionType === GET_GST_DETAILS){
            let viewType="GST"
            props.actions.documentVerificationViewDetails(viewType,(data)=>{
                setGlobalLoader(false)
                setDocumentVerificationViewDetailsData(data)
                setGstDetails([{key:GST_NUMBER,
                value: "",
                options:data?.DocumentDetails?.Data?.GstNumberArr,
                selectedValue:"",
                isSelectedIndex:-1,
                label:"",
                error: "",Button:{loaderVisible:false,text:STATUS_VERIFY,color:'red',action:GST_ACTION,disabled:true,visible:false,queryKey:GST_NUMBER}}])

                if(data?.DocumentDetails?.Data?.EntityDetails){
                    setEntityDetails(data?.DocumentDetails?.Data?.EntityDetails)
                }
            },(err)=>{
               
            })
        }

        

        if (props?.actionType === GET_UPLOAD_DETAILS){
            let viewType="UPLOAD"
            props.actions.documentVerificationViewDetails(viewType
                // ,(data)=>{
               
                
               
            )
        }

        if (props.actionType === GET_RES_LOCATION_DETAILS && parentToggler!==(ADD_LOCATION_SIGN_UP + "_" + MANUALLY)){
            let actionSource = ""
            if(!props?.showLocationModal){
                actionSource=GET_RES_LOCATION_DETAILS
            }
            
            props.actions.getAddressDetailsUsingLatLong(actionSource,latitude!==0?latitude:props?.onboardingData?.Steps[1]?.CTA?.Action?.ActionData?.Latitude, longitude!==0 ?longitude : props?.onboardingData?.Steps[1]?.CTA?.Action?.ActionData?.Longitude)
        }

    },[])

    useEffect(()=>{
       
        if (props?.actionType === GET_PERSONAL_DETAILS || props?.actionType === GET_RES_LOCATION_DETAILS || props?.actionType === GET_RES_LOCATION_DETAILS_2 || props?.actionType === GET_PAN_DETAILS || props?.actionType === GET_GST_DETAILS || props?.actionType === GET_FSSAI_DETAILS || props?.actionType === GET_UPLOAD_DETAILS || props?.actionType === ADD_LOCATION_SIGN_UP){
            setTogglers({
                currentType : props?.actionType,
                loading: false, 
            });
        }

        if (props?.actionType === GET_GST_DETAILS ){
            setGlobalLoader(true)
        }

        if(props?.actionType === ADD_LOCATION_SIGN_UP){
            if(!props.userData && !getCookieForEntity("outletId","posOutletId")){
                props.actions.getUserData();
            }
        }

        if (props?.actionType === GET_PERSONAL_DETAILS && props?.getOwnerDetailsStatus?.state === stateTypes.STATE_INIT){
            props.actions.getOwnerDetails();
        }

        

        if (props?.actionType === GET_PAN_DETAILS ){
            let viewType=DOCUMENT_TYPE_PAN
            props.actions.documentVerificationViewDetails(viewType) 
        }
       

        if (props?.actionType === GET_GST_DETAILS){
            let viewType="GST"
            props.actions.documentVerificationViewDetails(viewType,(data)=>{
                setDocumentVerificationViewDetailsData(data)
                setGlobalLoader(false)
                setGstDetails([{key:GST_NUMBER,
                value: "",
                selectedValue:"",
                isSelectedIndex:-1,
                options:data?.DocumentDetails?.Data?.GstNumberArr,
                label:"",
                error: "",Button:{loaderVisible:false,text:STATUS_VERIFY,color:'red',action:GST_ACTION,disabled:true,visible:false,queryKey:GST_NUMBER}}])
                if(data?.DocumentDetails?.Data?.EntityDetails){
                    setEntityDetails(data?.DocumentDetails?.Data?.EntityDetails)
                }
            },(err)=>{
                
            })
        }

        if (props?.actionType === GET_FSSAI_DETAILS){
            let viewType="FSSAI"
            props.actions.documentVerificationViewDetails(viewType)
        }

        if (props?.actionType === GET_UPLOAD_DETAILS){
            let viewType="UPLOAD"
            props.actions.documentVerificationViewDetails(viewType)
        }

        if (props.actionType === GET_RES_LOCATION_DETAILS && parentToggler!==(ADD_LOCATION_SIGN_UP + "_" + MANUALLY)){
            let actionSource = ""
            if(!props?.showLocationModal){
                actionSource=GET_RES_LOCATION_DETAILS
            }
            props.actions.getAddressDetailsUsingLatLong(actionSource,latitude!==0?latitude:props?.onboardingData?.Steps[1]?.CTA?.Action?.ActionData?.Latitude, longitude!==0 ?longitude : props?.onboardingData?.Steps[1]?.CTA?.Action?.ActionData?.Longitude)
        }

    },[props?.actionType])

    const [timer ,setTimer] = useState(30);

    const sendTracking = (trackingData)=>{
        props.actions.trackingWithoutAuth(trackingData)
    }

    const setPanDetailsActions = (val,setLoader,type,btnText,errorMessage) =>{
    
        if (type === DOCUMENT_PAN){
            if (!setLoader){
                let LegalEntityNameValue =documentVerificationViewDetailsData?.DocumentDetails?.Data?.LegalEntityName?.length?documentVerificationViewDetailsData?.DocumentDetails?.Data?.LegalEntityName:val
                let visibleStatus = false
                if(LegalEntityNameValue?.length){
                    visibleStatus=true
                }
               
                setPanDetails([{key:PAN_NUMBER,
                value: panDetails[0]?.value,
                label:LABEL_PAN,
                error: errorMessage,Button:{loaderVisible:false,text:btnText,color:'red',action:PAN_ACTION,disabled:false,visible:false,queryKey:PAN_NUMBER}},{key:LEGAL_NAME,
                value: documentVerificationViewDetailsData?.DocumentDetails?.Data?.LegalEntityName?.length?documentVerificationViewDetailsData?.DocumentDetails?.Data?.LegalEntityName:val,
                label:"Legal entity name",
                error: "",visible:visibleStatus,disabled:true}])
    
                //also update the documentVerificationViewDetailsData
                let record = documentVerificationViewDetailsData
                record.DocumentDetails.Data.LegalEntityName = val
                setDocumentVerificationViewDetailsData(record)
            }else{
                let visibleStatus = documentVerificationViewDetailsData?.DocumentDetails?.Data?.LegalEntityName?.length? true:false

                setPanDetails([{key:PAN_NUMBER,
                value: panDetails[0]?.value,
                label:LABEL_PAN,
                error: errorMessage,Button:{loaderVisible:true,text:btnText,color:'red',action:PAN_ACTION,disabled:true,visible:true,queryKey:PAN_NUMBER}},{key:LEGAL_NAME,
                value: documentVerificationViewDetailsData?.DocumentDetails?.Data?.LegalEntityName,
                label:"Legal entity name",
                error: "",visible:visibleStatus,disabled:true}])
            }
        }else if (type === DOCUMENT_FSSAI){
            if (!setLoader){
                setFssaiDetails([{key:FSSAI_NUMBER,
                value: fssaiDetails[0]?.value,
                label:"FSSAI number",
                error: errorMessage,Button:{loaderVisible:false,text:btnText,color:'red',action:FSSAI_ACTION,disabled:true,visible:false,queryKey:FSSAI_NUMBER}}])
    
              
            }else{
                setFssaiDetails([{key:FSSAI_NUMBER,
                value: fssaiDetails[0]?.value,
                label:"FSSAI number",
                error: errorMessage,Button:{loaderVisible:true,text:btnText,color:'red',action:FSSAI_ACTION,disabled:true,visible:true,queryKey:FSSAI_NUMBER}}])
            }
        }else if(type === "gst"){
            if (!setLoader){
                setGstDetails(
                    [
                        {key:GST_NUMBER,
                        value:gstDetails[0]?.value,
                        label:gstDetails[0]?.label,
                        isSelectedIndex:gstDetails[0].isSelectedIndex,
                        selectedValue:gstDetails[0].selectedValue,
                        options:gstDetails[0].options,
                        error:errorMessage,Button:{loaderVisible:false,text:btnText,color:"red",action:GST_ACTION,disabled:true,visible:false,queryKey:GST_NUMBER}
                        }
                    ]
                )
            }else{
                setGstDetails(
                    [
                        {key:GST_NUMBER,
                        value:gstDetails[0]?.value,
                        label:gstDetails[0]?.label,
                        isSelectedIndex:gstDetails[0].isSelectedIndex,
                        options:gstDetails[0].options,
                        selectedValue:gstDetails[0].selectedValue,
                        error:errorMessage,Button:{loaderVisible:true,text:btnText,color:"red",action:GST_ACTION,disabled:true,visible:true,queryKey:GST_NUMBER}
                        }
                    ]
                )
            }
        }
        
    }

    useEffect(() => {
        if (RESEND_OTP_FLOWS.includes(togglers.currentType)) {
            if (timer > 0) {
                const t =
                    timer > 0 &&
                    setTimeout(() => {
                    setTimer(timer - 1);
                    }, 1000);
            
                return () => {
                    if (t) {
                    clearTimeout(t);
                    }
                };
            } 
        }

        if (togglers.currentType === GET_RES_LOCATION_DETAILS && parentToggler!==(ADD_LOCATION_SIGN_UP + "_" + MANUALLY)){
            let actionSource = ""
            if(!props?.showLocationModal){
                actionSource=GET_RES_LOCATION_DETAILS
            }
            props.actions.getAddressDetailsUsingLatLong(actionSource,latitude?latitude:props?.onboardingData?.Steps[1]?.CTA?.Action?.ActionData?.Latitude, longitude?longitude : props?.onboardingData?.Steps[1]?.CTA?.Action?.ActionData?.Longitude)
        }
        
    }, [timer,togglers.currentType]);

    useEffect(()=>{
        if (props?.documentVerificationDetails?.Action?.ActionType?.length>0){
            props.setActionType(props?.documentVerificationDetails?.Action?.ActionType)
        }

        if (props?.documentVerificationDetails?.DocumentDetails?.Type === "PAN"){
            let details = [
                {
                  key:"PAN Number",
                  value:props?.documentVerificationDetails?.DocumentDetails?.Data?.Number,
                  error:props?.documentVerificationDetails?.DocumentDetails?.Data?.NumberError,
                  keyType:"input",
                  icon:"",
                  editable:props?.documentVerificationDetails?.DocumentDetails?.Data?.IsEditable,
                  visible:true,
                },
                {
                  key:"Legal entity name",
                  value:props?.documentVerificationDetails?.DocumentDetails?.Data?.LegalEntityName,
                  error:props?.documentVerificationDetails?.DocumentDetails?.Data?.LegalEntityNameError,
                  keyType:"input",
                  icon:"",
                  editable:props?.documentVerificationDetails?.DocumentDetails?.Data?.LegalEntityNameEditable,
                  visible:props?.documentVerificationDetails?.DocumentDetails?.Data?.LegalEntityNameView,
                },
                {
                  key:"Date of birth/registration",
                  value:props?.documentVerificationDetails?.DocumentDetails?.Data?.DateOfRegistration,
                  error:props?.documentVerificationDetails?.DocumentDetails?.Data?.DateOfRegistrationError,
                  keyType:"calendar",
                  icon:"",
                  editable:props?.documentVerificationDetails?.DocumentDetails?.Data?.RegistrationDateEditable,
                  visible:props?.documentVerificationDetails?.DocumentDetails?.Data?.RegistrationDateView,
                }
            ]
            setPanDetails(details)
        }

        if (props?.documentVerificationDetails?.DocumentDetails?.PreSelectedDocument?.DocType === "FSSAI"){
            
            let details = [
                {
                  key:"FSSAI Dropdown",
                  keyType:"dropdown",
                  value:props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.Text, //from preselected only
                  icon:props?.documentVerificationDetails?.DocumentDetails?.Data?.DocumentCTA?.IconImage,
                  options: props?.documentVerificationDetails?.DocumentDetails?.Data?.DocumentCTA?.Action?.ActionData?.Documents, // these options will be having from documents array
                  visible: true,
                },
                {
                  key:"FSSAI Document",
                  label:props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.TextHint,
                  value:props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.Number,
                  error:!props?.documentNumberVerificationDetails?.VerificationStatus?.IsValid ? props?.documentNumberVerificationDetails?.VerificationStatus?.Text:"",
                  keyType:"input",
                  editable:props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.IsEditable,
                  inlineCTA : props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.VerifyCTA?.Text,
                  visible: true,
                }
            ]

            setFssaiDetails(details)
        }

        if (props?.documentVerificationDetails?.DocumentDetails?.Type === "UPLOAD"){
            let documents = props?.documentVerificationDetails?.DocumentDetails?.Data?.DocumentArray?.map((document)=>{
                return {
                    "CTAImage" : document?.CTAImage,
                    "CTAText" : document?.CTAText,
                    "DocumentHeading" : document?.DocumentHeading,
                    "DocumentSubHeading" : document?.DocumentSubHeading,
                    "DocumentType" : document?.DocumentType,
                    "ErrorImage" : document?.ErrorImage,
                    "ErrorString" : document?.ErrorString,
                    "Status" : document?.Status,
                    "UploadCTA" : document?.UploadCTA
                }
            })
           

            setUploadDetails(documents)
            
            setOnboardingCtaEnabled(!props?.documentVerificationDetails?.IsPrimaryCTAEnabled)
            setBottomMessage(props?.documentVerificationDetails?.BottomMessage)
        }

    },[props?.documentVerificationDetails])

    useEffect (() => {  
        if (props?.documentNumberVerificationDetails?.DocumentDetails?.Type === "FSSAI") {
            
            let details = [
                {
                  key:"FSSAI Dropdown",
                  keyType:"dropdown",
                  value:fssaiDetails[0].value, //from preselected only
                  icon:fssaiDetails[0].icon,
                  options: fssaiDetails[0].options, // these options will be having from documents array
                  visible: true,
                },
                {
                  key:"FSSAI Document",
                  label:fssaiDetails[1].label,
                  value:fssaiDetails[1].value,
                  error:!props?.documentNumberVerificationDetails?.VerificationStatus?.IsValid ? props?.documentNumberVerificationDetails?.VerificationStatus?.Text:"",
                  keyType:"input",
                  editable:fssaiDetails[1].editable,
                  inlineCTA : fssaiDetails[1].inlineCTA,
                  visible: true,
                }
            ]

            setFssaiDetails(details)
        }
    },[props?.documentNumberVerificationDetails])

    const resetAllValues = () => {
        if (togglers.currentType!==GET_PERSONAL_DETAILS && togglers.currentType!==GET_RES_LOCATION_DETAILS_2 && togglers.currentType!==GET_RES_LOCATION_DETAILS && togglers.currentType!==GET_PAN_DETAILS && togglers.currentType!==GET_GST_DETAILS && togglers.currentType!==GET_FSSAI_DETAILS && togglers.currentType!==GET_UPLOAD_DETAILS && togglers.currentType!== ADD_LOCATION_SIGN_UP){
            setTogglers({
                currentType : MODAL_TYPE_VERIFY_NUMBER,
                loading: false, 
            });
        }
        setOtp({
            value: "",
            error: ""
        })
        setPassword({
            value: "",
            checkValue: "",
            error: ""
        })
        setPhoneNumber({
            value: "",
            error: ""
        })
        setPersonalDetails([{key:"name",
        value: "",
        label:PERSONAL_DETAIL_LABEL,
        error: ""},{key:EMAIL,label:PERSONAL_DETAIL_EMAIL,
        value: "",
        error: ""}])

        setRestaurantDetails([{key:OUTLET_NAME,
        value: "",
        label:RESTAURANT_NAME,
        error: ""},{key:ADDRESS,
        value: "",
        label:COMPLETE_ADDRESS_LABEL,
        error: ""},{key:PINCODE,
        value: "",
        label:LABEL_PINCODE,
        error: ""},{key:KEY_FLOOR,
        value: "",
        label:LABEL_FLOOR,
        error: "",Heading:LABEL_FLOOR,options:FLOOR_OPTIONS},{key:KEY_LIFT,
        value: "",
        label:LABEL_LIFT_AVAILABLE,
        error: "",Heading:LABEL_LIFT_AVAILABLE,options:LIFT_OPTIONS}
        ,{key:LANDMARK,
        value: "",
        label:NEARBY_LANDMARK,
        error: "",Heading:NEARBY_LANDMARK}])

        // setPanDetails([{key:PAN_NUMBER,
        // value: "",
        // label:LABEL_PAN,
        // error: "",Button:{loaderVisible:false,text:STATUS_VERIFY,color:'red',action:PAN_ACTION,disabled:true,visible:true,queryKey:PAN_NUMBER}},{key:LEGAL_NAME,
        // value: "",
        // label:LEGAL_ENTITY_LABEL,
        // error: "",visible:false,disabled:true}]) 

        if (togglers.currentType===GET_PAN_DETAILS){
            props.setActionType("")
        }

        //reset get gst details

        // reset get fssai details
        //reset upload details also.
    }

    const closeModal = () => {
        if((parentToggler === ADD_LOCATION_SIGN_UP || parentToggler === ADD_LOCATION_SIGN_UP + "_" + MANUALLY ) && props?.showLocationModal){
            deleteCookieForEntity("token","posToken");
            deleteCookieForEntity("outletId","posOutletId");
            window.location.href = "/";
        }

        if(props?.actionType?.length && props?.actionType===GET_RES_LOCATION_DETAILS && parentToggler.length && parentToggler === GET_GST_DETAILS){
            props.setActionType(GET_GST_DETAILS)
            return
        }
        props.actions.resetVerifyUserData();
        props.actions.resetVerifyUserOTPData();
        resetAllValues();
        props.onClose();
        if (parentToggler.length){
            setParentToggler("")
        }
    }

    useEffect(()=>{
        if (props.verifyUserStatus.state === stateTypes.STATE_DONE){
            if ( props.verifyUserData.ActionData.ActionType === MODAL_TYPE_SIGN_UP) {
                props.history.push("/register")
                closeModal()
            }else{
                setTogglers({
                    ...togglers,
                    currentType: props.verifyUserData.ActionData.ActionType,
                    loading: false
                })
                setPhoneNumber({
                    value: props.verifyUserData.ActionData.ActionData.PhoneNumber,
                    error: ""
                })
            }
        }

        if (props.verifyUserStatus.state === stateTypes.STATE_FAIL && props.verifyUserData.errorData?.error?.errorToast === null){
            setPhoneNumber({
                ...phoneNumber,
                error: props.verifyUserStatus.error
            })
        }

        if (props.verifyUserStatus.state === stateTypes.STATE_BEGIN) {
            setTogglers({
                ...togglers,
                loading: true
            })
        }
    },[props.verifyUserStatus])


    useEffect(()=>{
        if (props.verifyUserOTPStatus.state === stateTypes.STATE_DONE) {
            setTogglers({
                ...togglers,
                loading: false,
                currentType: MODAL_TYPE_VERIFY_FORGET_PASSWORD_PASSWORD_MATCH
            })
            setOtp({
                ...otp,
                error: ""
            })
        }
        if (props.verifyUserOTPStatus.state === stateTypes.STATE_FAIL) {
            setTogglers({
                ...togglers,
                loading: false,
            })
        }

        if (props.verifyUserOTPStatus.state === stateTypes.STATE_FAIL &&  props.verifyUserOTPData.errorData?.error?.errorToast === null) {
            setOtp({
                ...otp,
                error: props.verifyUserOTPStatus.error
            })
        }

        if (props.verifyUserOTPStatus.state === stateTypes.STATE_BEGIN) {
            setTogglers({
                ...togglers,
                loading: true
            })
        }
    },[props.verifyUserOTPStatus])

    useEffect(()=>{
        if (props.forgetPasswordForWebStatus.state === stateTypes.STATE_BEGIN) {
            setTogglers({
                ...togglers,
                loading: true
            })
        }

        if (props.forgetPasswordForWebStatus.state === stateTypes.STATE_DONE || props.forgetPasswordForWebStatus.state === stateTypes.STATE_FAIL) {
            setTogglers({
                ...togglers,
                loading: false
            })
        }

    },[props.forgetPasswordForWebStatus])

    useEffect(()=>{
        if (props.signInStatus.state === stateTypes.STATE_FAIL && props.signInResponse.errorData?.error?.errorToast === null){
            if (togglers.currentType === MODAL_TYPE_VERIFY_PASSWROD || togglers.currentType === MODAL_TYPE_SIGN_UP_PASSWORD){
                setPassword({
                    ...password,
                    error: props.signInStatus.error
                })
            }else {
                setOtp({
                    ...otp,
                    error: props.signInStatus.error
                })
            }
        }
        if (props.signInStatus.state === stateTypes.STATE_BEGIN) {
            setTogglers({
                ...togglers,
                loading: true
            })
        }
        if (props.signInStatus.state === stateTypes.STATE_DONE || props.signInStatus.state === stateTypes.STATE_FAIL) {
            setTogglers({
                ...togglers,
                loading: false
            })
        }
    },[props.signInStatus])

    useEffect(()=>{
        if (props.sendSmsOTPStatus.state === stateTypes.STATE_FAIL && props.verifyUserOTPData.errorData?.error?.errorToast === null){
    
                setOtp({
                    ...otp,
                    error: props.sendSmsOTPStatus.error
                })
        }

        if (props.sendSmsOTPStatus.state === stateTypes.STATE_BEGIN) {
            setTogglers({
                ...togglers,
                loading: true
            })
        }
        if (props.sendSmsOTPStatus.state === stateTypes.STATE_DONE || props.sendSmsOTPStatus.state === stateTypes.STATE_FAIL) {
            setTogglers({
                ...togglers,
                loading: false
            })
        }

    },[
        props.sendSmsOTPStatus
    ])

    useEffect(()=>{
        if (props.addUserPhoneNumberAndReonboardStatus.state === stateTypes.STATE_BEGIN) {
            setTogglers({
                ...togglers,
                loading: true
            })
        } else if (props.addUserPhoneNumberAndReonboardStatus.state === stateTypes.STATE_DONE) {
            setTogglers({
                ...togglers,
                loading: false,
                currentType: ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP
            });
        } else if (props.addUserPhoneNumberAndReonboardStatus.state === stateTypes.STATE_FAIL) {
            setTogglers({
                ...togglers,
                loading: false
            })
        }
    },[props.addUserPhoneNumberAndReonboardStatus]);

    useEffect(()=>{
        if (props.addUserPhoneNumberAndReonboardVerifyOTPStatus.state === stateTypes.STATE_BEGIN) {
            setTogglers({
                ...togglers,
                loading: true
            });
        } else if (props.addUserPhoneNumberAndReonboardVerifyOTPStatus.state === stateTypes.STATE_DONE) {
            setTogglers({
                ...togglers,
                loading: false,
                currentType: ""
            });
            props.toggleAddPhonenumberAndReonboardModal();
            if (props.addPhoneNumberAndReonboardUser?.Token?.length > 0) {
                setCookieForEntity("token","posToken", props.addPhoneNumberAndReonboardUser.Token,COOKIE_TTL_FOR_WEB)
                window.localStorage.removeItem("token")
            }
            window.location.reload(); 
        } else if (props.addUserPhoneNumberAndReonboardVerifyOTPStatus.state === stateTypes.STATE_FAIL) {
            setTogglers({
                ...togglers,
                loading: false
            });
        }
    }, [props.addUserPhoneNumberAndReonboardVerifyOTPStatus]);

    
    const getMobileVerificationButtons = () => {
        return [
            {
                title: "Continue",
                btnType: "solid",
                onClick: () => {
                    props.actions.verifyUser(phoneNumber.value)
                },
                disabled : validatePhoneNumber(phoneNumber.value) === null ? false : true
            }
        ]
    }

    const getMobileVerificationButtonsForUserPhoneNumberAddAndReonboard = () => {
        return [
            {
                title: "Continue",
                btnType: "solid",
                onClick: () => {
                    props.actions.addUserPhoneNumberAndReonboard(phoneNumber.value)
                },
                disabled : validatePhoneNumber(phoneNumber.value) === null ? false : true
            }
        ]
    }


    const getVerifyPasswordButton = () => {
        return [
            {
                title: "Login with OTP",
                btnType: "outline",
                onClick: () => {
                   setTogglers({
                    currentType: MODAL_TYPE_VERIFY_OTP,
                    loading: false
                   })
                   setOtp({
                        value: "",
                        error: ""
                   })
                   setPassword({
                    value: "",
                    checkValue: "",
                    error: "" 
                   })
                   props.actions.trackingWithoutAuth(props?.verifyUserData?.OTPTrackingParams)
                },
                disabled : false
            },
            {
                title: STATUS_VERIFY,
                btnType: "solid",
                onClick: () => {
                  props.actions.signIn({
                    "Name": phoneNumber.value,
                    "Password": password.value,
                    "OTP": otp.value
                  })
                },
                disabled : password.value.length === 0
            }
        ]
    }

    const getVerifyOTPButton = () =>{
        let btnCondition = togglers.currentType === MODAL_TYPE_SIGN_UP_OTP ?false:true
        btnCondition = !btnCondition ? tncStatus.value :btnCondition
        const verifyOTPButtons = []
        if (togglers.currentType!==MODAL_TYPE_SIGN_UP_OTP){
            verifyOTPButtons.push({
            title: "Login with password",
            btnType: "outline",
            onClick: () => {
                setTogglers({
                    currentType: MODAL_TYPE_VERIFY_PASSWROD,
                    loading: false
                   })
                setPassword({
                    value: "",
                    checkValue: "",
                    error: "" 
                })
                setOtp({
                    value: "",
                    error: ""
               })
               if (props?.verifyUserData?.PasswordTrackingParams){
                 props.actions.trackingWithoutAuth(props?.verifyUserData?.PasswordTrackingParams)
               }
            },
            disabled : false
            })
        }


        verifyOTPButtons.push({
                title: STATUS_VERIFY,
                btnType: "solid",
                onClick: () => {

                  props.actions.signIn({
                    "Name": phoneNumber.value,
                    "Password": password.value,
                    "OTP": otp.value,
                    [tncStatus.key]:tncStatus.value,
                    [receiveWhatsAppNotif.key]:receiveWhatsAppNotif.value
                  },(signInResponse)=>{
                   if(signInResponse?.response?.Action?.ActionType === ADD_LOCATION_SIGN_UP){

                        setTogglers({
                            currentType : ADD_LOCATION_SIGN_UP,
                            loading:false
                        });
                        props.setLocationModal(true)
                    }
                    return
                  })
                },
                disabled : otp.value.length !== 6 || !btnCondition
        })

        return verifyOTPButtons
    }

    const getVerifyOTPButtonForAddUserPhoneNumberAndReonboard = () => {
        const verifyOTPButtons = [];
        verifyOTPButtons.push({
            title: "Verify OTP",
            btnType: "solid",
            onClick: () => {
              props.actions.addUserPhoneNumberAndReonboardVerifyOTP(phoneNumber.value, otp.value);
            },
            disabled : otp.value.length !== 6
        });
        return verifyOTPButtons;
    }

    const getVerifyOTPForgetPasswordButton = () =>{
        return [
            {
                title: STATUS_VERIFY,
                btnType: "solid",
                onClick: () => {
                  props.actions.verifyUserOtp(
                    phoneNumber.value,
                    otp.value,
                    "forgot_password"
                  )
                },
                disabled : otp.value.length !== 6
            }
        ]
    }


    const getVerifyForgetPasswordMatch = () =>{
        return [
            {
                title: "Reset password",
                btnType: "solid",
                onClick: () => {
                    if (password.value !== password.checkValue) {
                        setPassword({
                            ...password,
                            error: "Password doesn’t match. Please enter again"
                        })
                        return
                    }
                    if (getValidatedPasswordArray(password.value).filter(i => !i.isValid).length > 0) {
                        setPassword({
                            ...password,
                            error: "Please adhere to password checks"
                        })
                        return
                    }
                    setPassword({
                        ...password,
                        error: ""
                    })
                  props.actions.forgotPasswordVerifyOtp(
                   {
                    NewPassword: password.value,
                    OtpNumber: otp.value,
                    PhoneNumber: phoneNumber.value
                   },
                   ()=>{
                    props.actions.signIn({
                        "Name": phoneNumber.value,
                        "Password": password.value,
                      })
                   }
                  )
                },
                disabled : password.value.length === 0 || password.checkValue.length === 0 
            }
        ]
    }

    const checkPersonalDetailsCTADisabled = () => {
       
        let arr = personalDetails.filter((detail)=> {
            return detail?.value?.length>0
        })

        let enabled = !(arr?.length === personalDetails?.length)

       
        let result = RegexEmail(personalDetails[1].value)
        if(!result){
            return true
        }

        return enabled
    }

    const getPersonalDetailsButtons = () => {
        return [
            {
                title: "Complete",
                btnType: "solid",
                onClick: () => {
                    let result = RegexEmail(personalDetails[1].value)

                    if(!result){
                        let errorMessage = "Invalid email address"
                        let arr = personalDetails.map((field,index)=>{
                            if (field.key!=="email"){
                                return field
                            }else{
                                return {...field,["error"]:errorMessage}
                            }
                        })
                        setPersonalDetails(arr)
                        return
                    }

                    let params = {
                        "FullName": personalDetails[0].value,
                        "Email": personalDetails[1].value
                    }
                    

                    if (personalDetails?.length >= 2 && personalDetails[2]?.value && personalDetails[2]?.value.length > 0){
                        params.BusinessType = personalDetails[2]?.value
                    }
                    
                    props.actions.completePersonalDetails(
                        params
                      ,(sucessData)=>{
                        sucessData=sucessData?.Action?.ActionData
                        closeModal()
                        setFinalStateModal({Image:sucessData?.Image,Open:true,Text:sucessData?.Heading,CTA:sucessData?.DismissCTA})
                      },(errorData)=>{
                        closeModal()
                        setFinalStateModal({Image:errorData?.errorToast?.ImagePath,Open:true,Text:errorData?.errorToast?.Text})
                      })
                },
                
               disabled: checkPersonalDetailsCTADisabled()
            }
        ]
    }

    const getRestaurantDetails = () => {
        return [
            {
                title:"Save and continue",
                btnType: "solid",
                onClick: () => {
                    let params = {
                        "Address": restaurantDetails[1].value,
                        "Floor": restaurantDetails[3].value,
                        "Latitude": props?.onboardingData?.Steps[1]?.CTA?.Action?.ActionData?.Latitude,
                        "Longitude": props?.onboardingData?.Steps[1]?.CTA?.Action?.ActionData?.Longitude,
                        "NearbyLandmark": restaurantDetails[5].value,
                        "OutletName": restaurantDetails[0].value,
                        "Pincode":restaurantDetails[2].value,
                        "PoiId": props.addressLocationDetails?.PoiDetails?.Id,
                        "CityId": props.addressLocationDetails?.CityId, //. from locaton Resp
                        "SubZoneId": props.addressLocationDetails?.SubZoneId, //. from locaton Resp
                        "LocationSubHeading": props?.addressLocationDetails?.LocationSubHeading,//. from locaton Resp
                        "ZoneId":props.addressLocationDetails?.ZoneId,
                        "LocationHeading":props?.addressLocationDetails?.LocationHeading
                    }
                    
                    if (updatedLocation.locationUpdated && updatedLocation.latitude>0 && updatedLocation.longitude>0){
                        params.Latitude = updatedLocation.latitude
                        params.Longitude = updatedLocation.longitude
                    }
                    

                    if(restaurantDetails[3].value !== "Ground"){
                        params.LiftAvailable =  Boolean(restaurantDetails[4].value === "Yes" ?true :false)
                    }

                    if(!props?.showLocationModal){
                        params.ActionSource = props?.onboardingData?.Steps[1]?.CTA?.Action?.ActionData?.ActionSource
                    }

                    let errorExist = false
                    let arr = []

                    if(restaurantDetails[1].value.length<10){
                        let errorMessage = "Address should be of minimum 10 characters"
                        errorExist = true
                        arr = restaurantDetails.map((field,index)=>{
                            if (field.key!=="address"){
                                return field
                            }else{
                                return {...field,["error"]:errorMessage}
                            }
                        })
                    }

                    if (restaurantDetails?.length>=2 && restaurantDetails[2].value?.length<6){
                        let errorMessage = "Please enter valid 6 digit pincode"
                        errorExist = true
                        arr = arr.map((field,index)=>{
                            if (field.key!=="pincode"){
                                return field
                            }else{
                                return {...field,["error"]:errorMessage}
                            }
                        })
                    }

                    if (errorExist) {
                        setRestaurantDetails(arr)
                        return
                    }
                    
                    props.actions.completeRestaurantDetails(params,(sucessData)=>{
                        sucessData=sucessData?.ActionData
                        closeModal()
                        setFinalStateModal({Image:sucessData?.Image,Open:true,Text:sucessData?.Heading,CTA:sucessData?.DismissCTA})
                      },()=>{})
               },
               disabled:!restaurantDetails[1].value?.length || !restaurantDetails[3].value?.length || !restaurantDetails[0].value?.length || !restaurantDetails[2].value?.length || restaurantDetails[1].error?.length ||  restaurantDetails[2].error?.length
        
            }
        ]
    }

    const handleConfirmLocation = (lat,lng) => {
        let params = {
            "Latitude": lat,
            "Longitude": lng,
            "ActionSource": ADD_LOCATION_SIGN_UP, //. from actionData Resp
            "CityId": props.addressLocationDetails?.CityId, //. from locaton Resp
            "SubZoneId": props.addressLocationDetails?.SubZoneId, //. from locaton Resp
            "LocationSubHeading": props?.addressLocationDetails?.LocationSubHeading,//. from locaton Resp
            "ZoneId":props.addressLocationDetails?.ZoneId,
            "LocationHeading":props?.addressLocationDetails?.LocationHeading
        }
        
        props.actions.completeRestaurantDetails(params,(sucessData)=>{
            sucessData=sucessData?.ActionData
            props.history.go()
        },()=>{})
    }

    const getLocationInputButtons = () =>{
        return [
            {
                title:"Confirm Location",
                btnType:"solid",
                onClick:() => {
                    if(parentToggler ===  ADD_LOCATION_SIGN_UP){
                        let params = {
                            "Latitude": latitude,
                            "Longitude": longitude,
                            "ActionSource": ADD_LOCATION_SIGN_UP, //. from actionData Resp
                            "CityId": props.addressLocationDetails?.CityId, //. from locaton Resp
                            "SubZoneId": props.addressLocationDetails?.SubZoneId, //. from locaton Resp
                            "LocationSubHeading": props?.addressLocationDetails?.LocationSubHeading,//. from locaton Resp
                            "ZoneId":props.addressLocationDetails?.ZoneId,
                            "LocationHeading":props?.addressLocationDetails?.LocationHeading
                        }
                        
                        props.actions.completeRestaurantDetails(params,(sucessData)=>{
                            sucessData=sucessData?.ActionData
                            closeModal()
                            window.location.href = "/";
                          },()=>{})
                    }else{
                        props.setActionType(GET_RES_LOCATION_DETAILS_2)
                    }
                }
            }
        ]
    }

    const getPanDetailsButton = () =>{

        let disabledStatus = true

        if (!props?.documentVerificationDetails?.IsPrimaryCTAEnabled){
            let status = panDetails[0]?.value !== props?.documentVerificationDetails?.DocumentDetails?.Data?.Number && panDetails[0]?.value?.length === 10

            if (props?.documentVerificationDetails?.DocumentDetails?.Data?.LegalEntityNameView && panDetails[1]?.value?.length <= 0){
                status = status && false
            }

            if ( props?.documentVerificationDetails?.DocumentDetails?.Data?.RegistrationDateView && panDetails[2]?.value?.length <= 0){
                status = status && false
            }

            if (props?.documentVerificationDetails?.DocumentDetails?.Data?.DateOfRegistration !== panDetails[2]?.value){
                status = true
            }

            if (status) {
                disabledStatus = false
            }

        }else{
            disabledStatus = false
        }

        return [
            {   
                title: props?.documentVerificationDetails?.PrimaryCTA?.Text,
                btnType: "solid",
                onClick: () => {
                    props.actions.trackPopUp(props?.documentVerificationDetails?.PrimaryCTA?.Action?.TrackingParams)
                    if (props?.documentVerificationDetails?.PrimaryCTA?.Action?.ActionType === "ENDPOINT"){
                        let params = {
                            "documentType" : "PAN",
                            "documentNumber" : panDetails[0].value,
                            "entityName" : panDetails[1].value
                        }

                        if (panDetails[2]?.value !== undefined){
                            let date = moment(panDetails[2]?.value).format("DD/MM/YYYY")
                            
    
                            if (date?.length>0){
                                params.dateOfRegistration = date
                            }
                        }
                        
                        props.actions.callCustomEndpoint(props?.documentVerificationDetails?.PrimaryCTA?.Action?.ActionData,params)
                    }
                },
                disabled :  disabledStatus
            }
        ]
    }

    const handleVerifyCTA = () => {
    
        let option = fssaiDetails[0]?.options?.filter((option)=>{
            return fssaiDetails[0].value === option?.Text
        })

        

        if (option?.length == 0 || option === undefined){
            option=[
                {
                    "DocType":props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.DocType?.length > 0 ? props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.DocType : ""
                }
            ]
        }

    
       
        if (option?.length> 0 &&  option[0].DocType?.length > 0) {
            props.actions.verifyPanAndLegalEntityName(VERIFY_ACTION_ENDPOINT(option[0].DocType) + fssaiDetails[1].value)
        }
    }
    
    const getGstButtons = () => {
        let disabledCondition=true
            if(gstDetails[0].options?.length ==1){
                disabledCondition =  gstDetails[0].Button?.text===STATUS_VERIFIED?false:true
            }else if(gstDetails[0].options?.length > 1){
                if(gstDetails[0].selectedValue!==ENTER_GST_MANUALLY){
                    disabledCondition = gstDetails[0].isSelectedIndex === -1 ? true : false
                }else {
                    disabledCondition =  gstDetails[0].Button?.text===STATUS_VERIFIED?false:true
                }
            }else{
                disabledCondition = togglers.loading
            }

        return [
            {
                title:documentVerificationViewDetailsData?.SecondaryCTA?.Text,
                btnType:"outline",
                onClick: () => {
                    if (documentVerificationViewDetailsData?.SecondaryCTA?.Action?.ActionData?.CTA?.Action?.ActionType === GET_UPLOAD_DETAILS && gstDetails[0].selectedValue!==ENTER_GST_MANUALLY&& gstDetails[0].isSelectedIndex!==-1){
                        setTogglers({
                            ...togglers,
                            loading: true
                        })
                        props.actions.trackPopUp(documentVerificationViewDetailsData?.SecondaryCTA?.Action?.TrackingParams)
                        props.actions.verifyPanAndLegalEntityName(gstDetails[0].Button.action + gstDetails[0].selectedValue,(sucessdata)=>{
                            setTogglers({
                                ...togglers,
                                loading: false
                            })
                            props.setActionType(documentVerificationViewDetailsData?.PrimaryCTA?.Action?.ActionType)
                            setDocumentVerificationViewDetailsData({})
                        },(error)=>{})
                    }else{
                        props.setActionType(documentVerificationViewDetailsData?.SecondaryCTA?.Action?.ActionData?.CTA?.Action?.ActionType)
                        setDocumentVerificationViewDetailsData({})
                        props.actions.trackPopUp(documentVerificationViewDetailsData?.SecondaryCTA?.Action?.TrackingParams)
                    }
                },
                disabled: ""
            },{
                title:documentVerificationViewDetailsData?.PrimaryCTA?.Text,
                btnType:"solid",
                onClick: () => {
                    if((documentVerificationViewDetailsData?.PrimaryCTA?.Action?.ActionType === GET_UPLOAD_DETAILS) && gstDetails[0].selectedValue!==ENTER_GST_MANUALLY && gstDetails[0].isSelectedIndex!==-1){
                        setTogglers({
                            ...togglers,
                            loading: true
                        })
                       props.actions.verifyPanAndLegalEntityName(gstDetails[0].Button.action + gstDetails[0].selectedValue,(sucessdata)=>{
                        setTogglers({
                            ...togglers,
                            loading: false
                        })
                            props.setActionType(documentVerificationViewDetailsData?.PrimaryCTA?.Action?.ActionType)
                            setDocumentVerificationViewDetailsData({})
                        },(error)=>{console.log(error)})
                        props.actions.trackPopUp(documentVerificationViewDetailsData?.PrimaryCTA?.Action?.TrackingParams)
                    }else{
                        props.setActionType(documentVerificationViewDetailsData?.PrimaryCTA?.Action?.ActionType)
                        setDocumentVerificationViewDetailsData({})
                        props.actions.trackPopUp(documentVerificationViewDetailsData?.PrimaryCTA?.Action?.TrackingParams)
                    }
                },

                
                disabled: disabledCondition
            },
        ]
    }

    const checkFssaiButtonEnabled = () => {
        if (props?.documentVerificationDetailsState.state===stateTypes.STATE_DONE  && 
            props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.Number?.length >= 0){
                if (props?.documentNumberVerificationDetailsState.state === stateTypes.STATE_DONE && props?.documentNumberVerificationDetails?.DocumentDetails?.PrimaryCTAEnabled){
                    return false
                }
                return !props?.documentVerificationDetails?.IsPrimaryCTAEnabled
        }else if (fssaiDetails?.length>=1 && fssaiDetails[1].value.toLowerCase()?.length > 0  && props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.Number !== panDetails[1].value.toLowerCase()){
            return false
        }
        return true
    }

    const getFssaiButtons = () => {
        return [
            {
                title:props?.documentVerificationDetails?.PrimaryCTA?.Text,
                btnType:"solid",
                onClick: () => {
                    props.actions.trackPopUp(props?.documentVerificationDetails?.PrimaryCTA?.Action?.TrackingParams)
                    props.setActionType(props?.documentVerificationDetails?.PrimaryCTA?.Action?.ActionType)
                },
                disabled: checkFssaiButtonEnabled() //IsPrimaryCTAEnabled
            },
            {
                title:props?.documentVerificationDetails?.SecondaryCTA?.Text,
                btnType:"outline",
                onClick: () => {
                    props.actions.trackPopUp(props?.documentVerificationDetails?.SecondaryCTA?.Action?.TrackingParams)
                    props.setActionType(props?.documentVerificationDetails?.SecondaryCTA?.Action?.ActionType)
                },
                disabled: ""
            }
        ]
    }

    const getUploadDetailsButtons = () => {
        return [
            {
                title:props?.documentVerificationDetails?.PrimaryCTA?.Text,
                btnType:"solid",
                onClick: () => {
                    
                    props.actions.completeUploadDetails({},(sucessData)=>{
                        sucessData=sucessData?.Action?.ActionData
                        closeModal()
                        setFinalStateModal({Image:sucessData?.Image,Open:true,Text:sucessData?.Heading,CTA:sucessData?.DismissCTA})
                    })
                },
               disabled: onboardingCtaEnabled
            }
        ]
    }

    function deleteCookie(name) {
        document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }

    const handleCompleteOnboardingModalActions = (clickAction) => { 
        if(clickAction === "Complete setup"){
            if(props?.isCheckoutSource){
                props.history.push(ROUTE_WITH_QUERY_CHECKOUT)
            }else if(props?.isCartSource){
                props.history.push(ROUTE_WITH_QUERY_CART)
            }else{
                props.history.push(ROUTE_WITH_QUERY_HOME)
            }
        }else if (clickAction === LATER_CTA){
            props.onClose()
        }else{
            deleteCookieForEntity("token","posToken");
            deleteCookieForEntity("outletId","posOutletId");
            window.location.href = "/";
        }
    }

    const getOnboardingButtons = () => {
        return [
            {
                title:props?.completeSetupModalData?.SecondaryCTA?.Text,
                btnType:"outline",
                onClick: () => {
                    props.actions.trackPopUp(props?.completeSetupModalData?.SecondaryCTA?.Action?.TrackingParams)
                    handleCompleteOnboardingModalActions(props?.completeSetupModalData?.SecondaryCTA?.Text)
                }
            },
            {
                
                title:props?.completeSetupModalData?.CTA?.Text,
                btnType:"solid",
                onClick: () => {
                    props.actions.trackPopUp(props?.completeSetupModalData?.CTA?.Action?.TrackingParams)
                    handleCompleteOnboardingModalActions(props?.completeSetupModalData?.CTA?.Text)
                }
            }, 
        ]
    }
    const handleFocusOnCurrentPosition = (cta) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              setLatitude(latitude)
              setLongitude(longitude)
              setTogglers({
                    ...togglers,
                    currentType: GET_RES_LOCATION_DETAILS,
                    loading:false,
                })

              if(cta === "primary"){
                props.actions.trackPopUp(props?.locationModalData?.PrimaryCTA?.Action?.TrackingParams)
              }else if(cta === "secondary"){
                props.actions.trackPopUp(props?.locationModalData?.SecondaryCTA?.Action?.TrackingParams)
              }

            },
            (error) => {
              setParentToggler(ADD_LOCATION_SIGN_UP + "_" + MANUALLY)
                            setTogglers({
                                ...togglers,
                                currentType: GET_RES_LOCATION_DETAILS,
                                loading: false
                            })
            }
          );
        } else {
          setParentToggler(ADD_LOCATION_SIGN_UP + "_" + MANUALLY)
                            setTogglers({
                                ...togglers,
                                currentType: GET_RES_LOCATION_DETAILS,
                                loading: false
                            })
        }
    };

    const handleSetLatitudeAndLongitude = (lat,long) => {
        setUpdatedLocation({locationUpdated:true,latitude:lat,longitude:long})
    }

    const getAddLocationButtons = () => {
        return [
            
            {
                title:props?.locationModalData?.SecondaryCTA?.Text,
                btnType:"outline",
                onClick: () => {
                    if(props?.locationModalData?.SecondaryCTA?.Text === "Allow"){
                        props.actions.trackPopUp(props?.locationModalData?.SecondaryCTA?.Action?.TrackingParams)

                        setTogglers({
                            ...togglers,
                            loading: true
                        })
                        navigator.permissions.query({ name: 'geolocation' })
                        .then((permission)=> {
                          if (permission.state === "granted"){
                            
                            handleFocusOnCurrentPosition("secondary")
                            setParentToggler(ADD_LOCATION_SIGN_UP)
                            return
                          }else if(permission.state === "prompt"){
                            handleFocusOnCurrentPosition("secondary") 
                            setParentToggler(ADD_LOCATION_SIGN_UP)
                            return
                          }else{
                            setParentToggler(ADD_LOCATION_SIGN_UP + "_" + MANUALLY)
                            setTogglers({
                                ...togglers,
                                currentType: GET_RES_LOCATION_DETAILS,
                                loading: false
                            })
                          }
                          
                        }).catch((err) =>{
                            setTogglers({
                                ...togglers,
                                loading: false
                            })
                        })
                    }else{
                        props.actions.trackPopUp(props?.locationModalData?.SecondaryCTA?.Action?.TrackingParams)
                        setParentToggler(ADD_LOCATION_SIGN_UP + "_" + MANUALLY)
                        setTogglers({
                            ...togglers,
                            currentType: GET_RES_LOCATION_DETAILS
                        })
                    }
                }
            },
            {
                title:props?.locationModalData?.PrimaryCTA?.Text,
                btnType:"solid",
                onClick: () => {
                    if(props?.locationModalData?.PrimaryCTA?.Text === "Allow"){
                        props.actions.trackPopUp(props?.locationModalData?.PrimaryCTA?.Action?.TrackingParams)
                   
                        setTogglers({
                            ...togglers,
                            loading: true
                        })
                        navigator.permissions.query({ name: 'geolocation' })
                        .then((permission)=> {

                          if (permission.state === "granted"){
                             handleFocusOnCurrentPosition("primary") 
                             setParentToggler(ADD_LOCATION_SIGN_UP)
                             return
                          }else if(permission.state === "prompt"){
                            handleFocusOnCurrentPosition("primary") 
                            setParentToggler(ADD_LOCATION_SIGN_UP)
                            return
                          }else{
                            setParentToggler(ADD_LOCATION_SIGN_UP + "_" + MANUALLY)
                            setTogglers({
                                ...togglers,
                                currentType: GET_RES_LOCATION_DETAILS,
                                loading: false
                            })
                            
                        }
                        }).catch((err) =>{
                          
                            setTogglers({
                                ...togglers,
                                loading: false
                            })
                        })
                    }else{
                        props.actions.trackPopUp(props?.locationModalData?.PrimaryCTA?.Action?.TrackingParams)
                        setParentToggler(ADD_LOCATION_SIGN_UP + "_" + MANUALLY)
                        setTogglers({
                            ...togglers,
                            currentType: GET_RES_LOCATION_DETAILS
                      })
                    }
                   
                },
                disabled:togglers.loading
            }
        ]
    }

    const getButtons = () => {
        switch (togglers.currentType){ 
            case MODAL_TYPE_VERIFY_NUMBER: 
                return getMobileVerificationButtons()
            case MODAL_TYPE_VERIFY_PASSWROD:
            case MODAL_TYPE_SIGN_UP_PASSWORD: 
                return getVerifyPasswordButton()
            case MODAL_TYPE_VERIFY_OTP: 
            case MODAL_TYPE_SIGN_UP_OTP:
                return getVerifyOTPButton()
            case MODAL_TYPE_VERIFY_FORGET_PASSWORD_OTP: 
                return getVerifyOTPForgetPasswordButton()
            case MODAL_TYPE_VERIFY_FORGET_PASSWORD_PASSWORD_MATCH: 
                return getVerifyForgetPasswordMatch()
            case GET_PERSONAL_DETAILS:
                return getPersonalDetailsButtons()
            case GET_RES_LOCATION_DETAILS_2:
                return getRestaurantDetails()
            case GET_RES_LOCATION_DETAILS:
                return getLocationInputButtons()
            case GET_PAN_DETAILS:
                return getPanDetailsButton()
            case GET_GST_DETAILS:
                return getGstButtons()
            case GET_FSSAI_DETAILS:
                return getFssaiButtons()
            case GET_UPLOAD_DETAILS:
                return getUploadDetailsButtons()
            case COMPLETE_ONBOARDING_SETUP:
                return getOnboardingButtons()
            case ADD_LOCATION_SIGN_UP:
                return getAddLocationButtons()
            case ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER:
                return getMobileVerificationButtonsForUserPhoneNumberAddAndReonboard()
            case ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP:
                return getVerifyOTPButtonForAddUserPhoneNumberAndReonboard()
            default: 
                return []
        }
    }

    const handleBackPressOnModal = (currentType,actionType) => {
        if(currentType === GET_UPLOAD_DETAILS && uploadDetails.length === 1){
            props.setActionType(GET_GST_DETAILS)
        }else if(currentType === GET_UPLOAD_DETAILS && uploadDetails.length === 2){
            props.setActionType(GET_FSSAI_DETAILS)
        }else{
            props.setActionType(actionType)
        }
    }

    const renderPasswordInputSubHeading = () => {
        return (
            <div className="d-flex fs-16">
            <div className="mr-2">Forgot password?</div>
            <div className={`${styles.redColorText} pointer`} onClick ={()=> {
                setTogglers({
                    ...togglers,
                    currentType: MODAL_TYPE_VERIFY_FORGET_PASSWORD_OTP
                })
                setOtp({
                    value: "",
                    error: ""
                })
                setPassword({
                    value: "",
                    checkValue: "",
                    error: ""
                })
                props.actions.trackingWithoutAuth(props?.verifyUserData?.ForgetPasswordParams)
            }}>Recover account</div>
        </div>
        )
    }

    const getSubHeadingPasswordMatch = ()=> {
        let validatedArray = getValidatedPasswordArray(password.value);
        return (
            <div className="d-flex flex-wrap">
                {validatedArray.map(item => {
                    return <div className={(!item.isValid ? styles.colorGreyText : styles.tealTextColor) + " d-flex align-items-center "+ `${styles.width50} mt-2` }>
                        <div className="mr-2"><Check size={18} color= {!item.isValid ? grey.z400 :teal.z600}/></div>
                        <div>{item.label}</div>
                    </div>
                })}
            </div>
        )
    }

    const renderOTPInputSubHeading = () => {
        return (
            <>
            {timer <= 9 ? (
                <div className={`fs-20 w-600 `}> 00:0{timer}</div>
              ) : (
                <div className={`fs-20 w-600 `}> 00:{timer}</div>
              )
            }
            <div className="d-flex">
                <div className="mr-2">Didn’t receive the code?</div>
                <div onClick={()=> 
                {   if (timer === 0){
                    setTimer(30)
                    if (togglers.currentType === ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP) {
                        props.actions.addUserPhoneNumberAndReonboard(phoneNumber.value)
                    } else {
                        props.actions.forgotPasswordEnterPhone(phoneNumber.value , togglers.currentType === MODAL_TYPE_VERIFY_OTP ?  "sign_in" : "forgot_password")
                    }
                }
                }
            } className={timer === 0 ? `${styles.redColorText} pointer` : styles.grey300Text  }>Resend now</div>
            </div>
              </>
        )
    }

    const getCheckboxData = () => {
        return [props?.verifyUserData?.ActionData?.ActionData?.TncCheckBox,props?.verifyUserData?.ActionData?.ActionData?.WhatsAppCheckBox]
    }

    const getFinalStateModal = () => {
        
        return <div> 
            <div style={{backgroundColor:finalStateModal.backgroundColor,borderColor:finalStateModal.borderColor}} className={`${styles.finalStateBox} wd-100 height-100 d-flex flex-column justify-content-center align-items-center`}>
                <div className={`${styles.finalStateImage} d-flex justify-content-center align-items-center`} ><Image classname={`${styles.widthInherit}`} source={finalStateModal?.Image}/></div>
                <div className={`${styles.finalStateText} w-700`}><b>{finalStateModal?.Text}</b></div>
            
            </div>
            <div onClick={()=>{props.history.go(0);}} style={{color:'red'}} className={`${styles.finalStateButton} pointer fs-16 wd-100 d-flex justify-content-center align-items-center`} >{finalStateModal?.CTA?finalStateModal.CTA:CTA_OKAY}</div>
        </div>
    }

    const getOnboardingsetUpModal = () => {
        let Buttons = getButtons()
        return <div><div className={` d-flex justify-content-center align-items-center flex-column`}>
             <div className={`${styles.onboardingImage}`}>
                <Image classname={`${styles.widthInherit} ${styles.onboardingModalImage}`} source={props?.completeSetupModalData?.Image}/>
             </div>
             <div className={`fs-24 w-700 text-align-center  ${styles.onboardingHeader}`}>
                 {props?.completeSetupModalData?.Heading}
             </div>
             <div className={`text-align-center fs-16 w-400 ${styles.onboardingSubHeader}`}>
                {props?.completeSetupModalData?.SubHeading}
             </div>
             
            </div>
            <div className={props?.completeSetupModalData?.SecondaryCTA?.Text !==LOGOUT ?`d-flex ${styles.onboardingButtonsHardCheck} justify-content-center align-items-center`:`d-flex ${styles.onboardingButtons}`}>{Buttons.map(
                (btn,index) => 
                <Button 
                onClick = {btn.onClick}
                disabled = {btn.disabled}
                appearance = {btn.btnType} className={(index !== Buttons.length -1 ? "mr-3" : "") + ` wd-100 ${styles.btnBorder}`}>{btn.title}</Button>
            
            )}
            </div>
        </div>
    }

    const getLocationSignUpModal = () => {
        let Buttons = getButtons()
        return <div><div className={` d-flex justify-content-center align-items-center flex-column`}>
        <div className={`${styles.onboardingImage}`}>
           <Image classname={`${styles.widthInherit} ${styles.onboardingModalImage}`} source={props?.locationModalData?.Header?.Image}/>
        </div>
        <div className={`fs-24 w-700 text-align-center  ${styles.onboardingHeader}`}>
        {props?.locationModalData?.Header?.Heading}   
        </div>
        <div className={`text-align-center fs-16 w-400 ${styles.onboardingSubHeader}`}>
        {props?.locationModalData?.Header?.SubHeading}
        </div>
        
       </div>
       <div className={`${styles.loaderPoisiton} d-flex justify-content-center wd-100`}>{togglers.loading ? <Spinner color={"#EF4F5F"}/>: <></>}</div>
       <div className={`d-flex ${styles.onboardingButtons}`}>{Buttons.map(
           (btn,index) => 
           <Button 
           onClick = {btn.onClick}
           disabled = {btn.disabled}
           appearance = {btn.btnType} className={(index !== Buttons.length -1 ? "mr-3" : "") + ` wd-100 ${styles.btnBorder}`}>{btn.title}</Button>
       
       )}
       </div>
   </div>
    }

    const preparePersonalDetails = (personalDetails) => {
        if (props?.getOwnerDetailsStatus?.state === stateTypes.STATE_INIT){
            return personalDetails
        }

        if (props?.ownerDetailsData?.Heading?.length){
            let arr = personalDetails.filter((detail)=>{
                return detail.key === props?.ownerDetailsData?.Heading
            })
            if (arr?.length === 0) {
                personalDetails.push(
                    {
                        key:props?.ownerDetailsData?.Heading,
                        value: "",
                        label:props?.ownerDetailsData?.Heading,
                        error: "",Heading:props?.ownerDetailsData?.Heading,options:props?.ownerDetailsData?.Options,
    
                    }
                )
            }
            
        }
       
        return personalDetails
    }

    const getPanDetailsFromState = () => {
        if ( props?.documentVerificationDetailsState.state === stateTypes.STATE_DONE && panDetails?.length === 0){
            let details = [
                {
                  key:"PAN Number",
                  value:props?.documentVerificationDetails?.DocumentDetails?.Data?.Number,
                  error:props?.documentVerificationDetails?.DocumentDetails?.Data?.NumberError ,
                  keyType:"input",
                  icon:"",
                  editable:props?.documentVerificationDetails?.DocumentDetails?.Data?.IsEditable,
                  visible:true,
                },
                {
                  key:"Legal entity name",
                  value:props?.documentVerificationDetails?.DocumentDetails?.Data?.LegalEntityName,
                  error:props?.documentVerificationDetails?.DocumentDetails?.Data?.LegalEntityNameError,
                  keyType:"input",
                  icon:"",
                  editable:props?.documentVerificationDetails?.DocumentDetails?.Data?.LegalEntityNameEditable,
                  visible:props?.documentVerificationDetails?.DocumentDetails?.Data?.LegalEntityNameView,
                },
                {
                  key:"Date of birth/registration",
                  value:props?.documentVerificationDetails?.DocumentDetails?.Data?.DateOfRegistration,
                  error:props?.documentVerificationDetails?.DocumentDetails?.Data?.DateOfReqgistrationError,
                  keyType:"calendar",
                  icon:"",
                  editable:props?.documentVerificationDetails?.DocumentDetails?.Data?.RegistrationDateEditable,
                  visible:props?.documentVerificationDetails?.DocumentDetails?.Data?.RegistrationDateView,
                }
            ]
            setPanDetails(details)
            return details
        }

        return panDetails
    }

    const getFssaiDetailsFromState = () => {
        if ( props?.documentVerificationDetailsState.state === stateTypes.STATE_DONE && fssaiDetails?.length === 0){
        
            let details = [
                {
                  key:"FSSAI Dropdown",
                  keyType:"dropdown",
                  value:props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.Text, //from preselected only
                  icon:props?.documentVerificationDetails?.DocumentDetails?.Data?.DocumentCTA?.IconImage,
                  options: props?.documentVerificationDetails?.DocumentDetails?.Data?.DocumentCTA?.Action?.ActionData?.Documents, // these options will be having from documents array
                  visible: true,
                },
                {
                  key:"FSSAI Document",
                  label:props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.TextHint,
                  value:props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.Number,
                  error: !props?.documentNumberVerificationDetails?.VerificationStatus?.IsValid ? props?.documentNumberVerificationDetails?.VerificationStatus?.Text:"",
                  keyType:"input",
                  editable:props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.IsEditable,
                  inlineCTA : props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.VerifyCTA?.Text,
                  inlineCTAColor : props?.documentVerificationDetails?.DocumentDetails?.Data?.PreSelectedDocument?.VerifyCTA?.TextColor,
                  visible: true,
                }
            ]

            setFssaiDetails(details)
           
            return details
        }

        return fssaiDetails
    }

    const getUploadDetailsFromState = () => {
        if ( props?.documentVerificationDetailsState.state === stateTypes.STATE_DONE && uploadDetails?.length === 0){
           
            let documents = props?.documentVerificationDetails?.DocumentDetails?.Data?.DocumentArray?.map((document)=>{
                
                return {
                    "CTAImage" : document?.CTAImage,
                    "CTAText" : document?.CTAText,
                    "DocumentHeading" : document?.DocumentHeading,
                    "DocumentSubHeading" : document?.DocumentSubHeading,
                    "DocumentType" : document?.DocumentType,
                    "ErrorImage" : document?.ErrorImage,
                    "ErrorString" : document?.ErrorString,
                    "Status" : document?.Status,
                    "UploadCTA" : document?.UploadCTA
                }
            })
            setUploadDetails(documents)
            setBottomMessage(props?.documentVerificationDetails?.BottomMessage)
            setOnboardingCtaEnabled(!props?.documentVerificationDetails?.IsPrimaryCTAEnabled)
            return documents;
        }
        return uploadDetails
    }

    useEffect(()=>{
        if (documentArray?.length>0 && props?.documentVerificationDetails?.DocumentDetails?.PollingDuration > 0) {
            let params = {
                "DocInfo":documentArray
            }
            props.actions.pollDocuments(params)
        }

    },[documentArray])

    const eligibleForPoll = () => {
        if ( props?.pollDocumentsDetails?.DocInfo?.length>0 && documentArray?.length>0 && documentArray?.length === props?.pollDocumentsDetails?.DocInfo?.length){
            let count = 0;
            props?.pollDocumentsDetails && props.pollDocumentsDetails.DocInfo.map((document)=>{
                documentArray.map((doc)=>{
                    if (doc.DocumentType === document?.DocumentType && doc?.JobId === document?.JobId){
                        count = count + 1
                    }
                })
            })

            return count  === props?.pollDocumentsDetails?.DocInfo?.length
        }

        return false 
    }

    useEffect(()=>{
        
        if (props?.pollDocumentDetailsState.state === stateTypes.STATE_INIT || (props?.pollDocumentDetailsState.state === stateTypes.STATE_DONE && props?.pollDocumentsDetails?.PollEnabled) ){
                
                let params = {
                    "DocInfo":documentArray
                }

                if (eligibleForPoll()){
                    if (intervalId> 0){
                        clearInterval(intervalId)
                    }

                       let Id = setInterval(() => {
                            props.actions.pollDocuments(params)
                        }, props?.documentVerificationDetails?.DocumentDetails?.PollingDuration);

                        setIntervalId(Id)
                }
        }

        if (!props?.pollDocumentsDetails?.PollEnabled && intervalId > 0) {
            clearInterval(intervalId)
        }

        if (props?.pollDocumentsDetails?.DocInfo?.length > 0) {
            let uploadDocuments = []
            const documentExist = new Map();

            props.pollDocumentsDetails && props.pollDocumentsDetails.DocInfo.map((doc)=>{
                uploadDetails?.length>0 && uploadDetails.map((document)=>{
                    if (document?.DocumentType === doc?.DocumentType){
                        documentExist.set(document?.DocumentType,true)
                        uploadDocuments.push({
                            "CTAImage" : doc?.CTAImage,
                            "CTAText" : doc?.CTAText,
                            "DocumentHeading" : document?.DocumentHeading,
                            "DocumentSubHeading" : document?.DocumentSubHeading,
                            "DocumentType" : document?.DocumentType,
                            "ErrorImage" : doc?.ErrorImage,
                            "ErrorString" : doc?.ErrorString,
                            "Status" : doc?.Status,
                            "UploadCTA" : doc?.UploadCTA
                        })
                    }
                })
            })

            uploadDetails?.length>0 && uploadDetails.map((document)=>{
                if (documentExist.get(document?.DocumentType) === undefined){
                    uploadDocuments.push(document)
                    documentExist.set(document?.DocumentType,true)
                }
            })

            if (uploadDocuments?.length>0){
                const indexMap = {};
                uploadDetails.forEach((item, index) => {
                    indexMap[item?.DocumentType] = index;
                });
                
                uploadDocuments.sort((recordOne, recordTwo) => indexMap[recordOne?.DocumentType] - indexMap[recordTwo?.DocumentType]);

                
                setUploadDetails(uploadDocuments)
            }

            setBottomMessage(props?.pollDocumentsDetails?.BottomMessage)
            setOnboardingCtaEnabled(!props?.pollDocumentsDetails?.IsCTAEnabled)
        }
        //now update the local state from the polling documents response.
    },[props?.pollDocumentsDetails])

    const handleDocumentTypesForPolling = (documentType,jobId) => {
        
        let newDocuments = [...documentArray]
        newDocuments = newDocuments.filter((document)=>{
            return document?.DocumentType !== documentType
        })

        newDocuments.push({"DocumentType":documentType,"JobId":jobId})

        setDocumentArray(newDocuments)
    }   

    const handleFileSelection = (event,index,option) => {

        const files = event.target.files;
        const newFiles = [...selectedFiles];
        newFiles[index] = files[0];
        setSelectedFiles(newFiles)

         let formData = new FormData();
         let documentType = ""

        if (uploadDetails?.length >= index) { 
            documentType = uploadDetails[index]?.DocumentType
        }

        formData.append("documentType", documentType);
        formData.append("file", newFiles[index]);
    
        props.actions.uploadDocuments(formData,(sucessData)=>{
            handleDocumentTypesForPolling(documentType,sucessData?.JobId)
        
        })   
    }

    const handlePanInputIntoCaps = (values) => {
        values=values.map((val)=>{
            if (val?.key === "PAN Number"){
                val.value = val?.value.toUpperCase()
                return val
            }else{
                return val
            }
            
        })

        setPanDetails(values)
    }

    const getCurrentScreen = () =>{
        if(globalLoader || (props?.documentVerificationDetailsState.state!==stateTypes.STATE_DONE && props?.documentVerificationDetailsState.state !== stateTypes.STATE_FAIL && (togglers.currentType === GET_PAN_DETAILS || togglers.currentType === GET_FSSAI_DETAILS || togglers.currentType === GET_UPLOAD_DETAILS))){
            return <div className="wd-100 height-650px d-flex justify-content-center align-items-center"><Spinner size="large" color={"#E03546"}/></div>
        }


        switch (togglers.currentType){
            case GET_PERSONAL_DETAILS:
               
                return  <LoginScreens
                Heading={PERSONAL_DETAIL_HEADING}
                SubHeading={PERSONAL_DETAIL_SUBHEADING}
                OnClose={closeModal}
                AllFields = {preparePersonalDetails(personalDetails)}
                SetAllFields = {setPersonalDetails}
                Buttons={getButtons()}
                InputType= {GET_PERSONAL_DETAILS}
                loading={togglers.loading}
                newModal={true}
                />
            
            case GET_RES_LOCATION_DETAILS:
                return <LocationModal
                    isOpen={props?.isOpen || ((parentToggler === ADD_LOCATION_SIGN_UP || parentToggler===(ADD_LOCATION_SIGN_UP + "_" + MANUALLY))&& togglers.currentType === GET_RES_LOCATION_DETAILS)}
                    onClose={closeModal}
                    showMarker={true}
                    latLongObject={{lat: latitude==0?props?.onboardingData?.Steps[1]?.CTA?.Action?.ActionData?.Latitude:latitude , lng: longitude==0?props?.onboardingData?.Steps[1].CTA?.Action?.ActionData?.Longitude:longitude}}
                    getAddressDetailsStatus={props.getAddressDetailsStatus}
                    getSearchLocations={props.actions.getSearchLocations}
                    getSearchLocationStatus={props.getSearchLocationStatus}
                    searchLocations={props.searchLocations}
                    getAddressDetails = {(lat , long , begin )=> { lat && long  && props.actions.getAddressDetailsUsingLatLong("",lat ,long, begin)}}
                    addressDetails = {props.addressLocationDetails}
                    locationMeta={props.locationMeta}
                    updateOutletLocation={props.actions.updateOutletLocation}
                    updateOutletLocationStatus ={props.updateOutletLocationStatus}
                    poiId ={props.addressLocationDetails?.PoiDetails?.Id}
                    updateOutletLocationResponse = {props.updateOutletLocationResponse}
                    history= {props.history}
                    dontLoadFromLatLong={parentToggler===(ADD_LOCATION_SIGN_UP + "_" + MANUALLY)}
                    loadGPSLocation={!parentToggler===(ADD_LOCATION_SIGN_UP + "_" + MANUALLY)}
                    positionArr={props.addressLocationDetails?.PoiDetails?.PolygonPoints?.length ? 
                        props.addressLocationDetails?.PoiDetails?.PolygonPoints.map(item => {
                            return {
                                lat: item.Latitude,
                                lng: item.Longitude
                            }
                        }) : []}
                    currentInputType = {togglers.currentType}
                    nextInputType = {GET_RES_LOCATION_DETAILS_2}
                    parentToggler={parentToggler}
                    setActionType={(actionType)=>{props.setActionType(actionType)}}
                    handleParentConfirmLocation={(lat,lng)=>handleConfirmLocation(lat,lng)}
                    handleSetLatitudeAndLongitude={(lat,lng)=>handleSetLatitudeAndLongitude(lat,lng)}
                    />

            case GET_RES_LOCATION_DETAILS_2:
                return <LoginScreens
                Heading={ props?.addressLocationDetails?.PopUpHeading?.length > 0 ? props?.addressLocationDetails?.PopUpHeading : "Restaurant details" }
                OnClose={closeModal}
                AllFields = {restaurantDetails}
                SetAllFields = {setRestaurantDetails}
                Buttons={getButtons()}
                InputType= {GET_RES_LOCATION_DETAILS_2}
                loading={togglers.loading}
                handleBackPress={handleBackPressOnModal}
                newModal={true}
                />
            case GET_PAN_DETAILS:
                return <LoginScreens
                Heading={props?.documentVerificationDetails?.Heading}
                SubHeading={props?.documentVerificationDetails?.SubHeading}
                OnClose={closeModal}
                AllFields = {getPanDetailsFromState(panDetails)}
                SetAllFields={(val)=>handlePanInputIntoCaps(val)}
                Buttons={getButtons()}
                InputType={GET_PAN_DETAILS}
                loading={togglers.loading}
                //Reset={setPanDetailsActions}
                documentVerificationData={panDetails}
                newModal={true}
                entityDetails={entityDetails}
                />
            case GET_GST_DETAILS:
                return <LoginScreens 
                Heading={documentVerificationViewDetailsData?.Heading}
                SubHeading={documentVerificationViewDetailsData?.SubHeading}
                OnClose={closeModal}
                Buttons={getButtons()}
                InputType={GET_GST_DETAILS}
                loading={togglers.loading}
                AllFields = {gstDetails}
                SetAllFields={setGstDetails}
                setGstValueAndSelectedParam={setGstValueAndSelectedParam}
                Reset={setPanDetailsActions}
                handleBackPress={handleBackPressOnModal}
                newModal={true}
                entityDetails={entityDetails}
                />
            
            case GET_FSSAI_DETAILS:
                return <LoginScreens
                 Heading={props?.documentVerificationDetails?.Heading}
                 SubHeading={props?.documentVerificationDetails?.SubHeading}
                 OnClose={closeModal}
                 Buttons={getButtons()}
                 InputType={GET_FSSAI_DETAILS}
                 loading={togglers.loading}
                 AllFields = {getFssaiDetailsFromState(fssaiDetails)}
                 SetAllFields={setFssaiDetails}
                 Reset={handleVerifyCTA}
                 handleBackPress={handleBackPressOnModal}
                 newModal={true}
                />
            
            case GET_UPLOAD_DETAILS:
                return <LoginScreens
                Heading={props?.documentVerificationDetails?.Heading}
                SubHeading={props?.documentVerificationDetails?.SubHeading}
                OnClose={closeModal}
                Buttons={getButtons()}
                InputType={GET_UPLOAD_DETAILS}
                loading={togglers.loading}
                AllFields={getUploadDetailsFromState(uploadDetails)}
                SetAllFields={setUploadDetails}
                handleFileSelections={(event,index,option)=>handleFileSelection(event,index,option)}
                BottomMessage={bottomMessage}
                
                handleBackPress={handleBackPressOnModal}
                newModal={true}
            />

            case MODAL_TYPE_VERIFY_NUMBER:
                return  <LoginScreens 
                ImageUrl={process.env.PUBLIC_URL + "/assets/images/AuthenticateLogin1.png"}
                Heading={"Enter mobile number"}
                SubHeading={"OTP will be sent to this number for verification"}
                OnClose={closeModal}
                Fields = {phoneNumber}
                SetFields = {setPhoneNumber}
                Buttons={getButtons()}
                InputType= {MODAL_TYPE_VERIFY_NUMBER}
                loading={togglers.loading}
                />
            case MODAL_TYPE_VERIFY_PASSWROD: 
                return  <LoginScreens 
                ImageUrl={process.env.PUBLIC_URL + "/assets/images/PasswordLogin1.png"}
                Heading={"Enter your password"}
                SubHeading={""}
                OnClose={closeModal}
                Fields = {password}
                SetFields = {setPassword}
                Buttons={getButtons()}
                InputType= {MODAL_TYPE_VERIFY_PASSWROD}
                renderModalInputSubHeading= {renderPasswordInputSubHeading}
                loading={togglers.loading}
                />
            case MODAL_TYPE_SIGN_UP_PASSWORD:
                return <LoginScreens
                ImageUrl={process.env.PUBLIC_URL + "/assets/images/PasswordLogin1.png"}
                OnClose={closeModal}
                Fields = {password}
                SetFields = {setPassword}
                Buttons={getButtons()}
                InputType={MODAL_TYPE_SIGN_UP_PASSWORD}
                renderModalInputSubHeading= {renderPasswordInputSubHeading}
                loading={togglers.loading}
                />
            case MODAL_TYPE_VERIFY_OTP: 
                return  <LoginScreens 
                ImageUrl={process.env.PUBLIC_URL + "/assets/images/OTPLogin.png"}
                Heading={"Enter verification code"}
                SubHeading={"6 digit OTP has been sent to +91 " + phoneNumber.value}
                OnClose={closeModal}
                Fields = {otp}
                SetFields = {setOtp}
                Buttons={getButtons()}
                onMountCall={()=> props.actions.forgotPasswordEnterPhone(phoneNumber.value, "sign_in")}
                InputType= {MODAL_TYPE_VERIFY_OTP}
                renderModalInputSubHeading={renderOTPInputSubHeading}
                loading={togglers.loading}
                />
            case MODAL_TYPE_SIGN_UP_OTP:
                return <LoginScreens
                ImageUrl={process.env.PUBLIC_URL + "/assets/images/OTPLogin.png"}
                Heading={"Enter verification code"}
                SubHeading={"6 digit OTP has been sent to +91 " + phoneNumber.value}
                OnClose={closeModal}
                Fields = {otp}
                SetFields = {setOtp}
                Buttons={getButtons()}
                onMountCall={()=> props.actions.forgotPasswordEnterPhone(phoneNumber.value, "sign_in")}
                InputType= {MODAL_TYPE_SIGN_UP_OTP}
                renderModalInputSubHeading={renderOTPInputSubHeading}
                loading={togglers.loading}
                CheckBoxes={getCheckboxData()}
                sendTracking={(data)=>sendTracking(data)}
                setTncStatus={(data)=>{setTncStatus({key:data.key,value:data.value})}}
                setReceiveWhatsAppNotid={(data)=>{setReceiveWhatsAppNotid({key:data.key,value:data.value})}}
            />
            case MODAL_TYPE_VERIFY_FORGET_PASSWORD_OTP: 
                return  <LoginScreens 
                ImageUrl={process.env.PUBLIC_URL + "/assets/images/OTPLogin.png"}
                Heading={"Enter verification code"}
                SubHeading={"6 digit OTP has been sent to +91 " + phoneNumber.value}
                OnClose={closeModal}
                Fields = {otp}
                SetFields = {setOtp}
                Buttons={getButtons()}
                onMountCall={()=> props.actions.forgotPasswordEnterPhone(phoneNumber.value, "forgot_password")}
                InputType= {MODAL_TYPE_VERIFY_FORGET_PASSWORD_OTP}
                renderModalInputSubHeading={renderOTPInputSubHeading}
                loading={togglers.loading}
                />
            case MODAL_TYPE_VERIFY_FORGET_PASSWORD_PASSWORD_MATCH: 
                return  <LoginScreens 
                ImageUrl={process.env.PUBLIC_URL + "/assets/images/PasswordReset.png"}
                Heading={"Reset your password"}
                SubHeading={getSubHeadingPasswordMatch()}
                OnClose={closeModal}
                Fields = {password}
                SetFields = {setPassword}
                Buttons={getButtons()}
                InputType= {MODAL_TYPE_VERIFY_FORGET_PASSWORD_PASSWORD_MATCH}
                loading={togglers.loading}
                />
            case ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER: 
                return  <LoginScreens 
                ImageUrl={process.env.PUBLIC_URL + "/assets/images/AuthenticateLogin1.png"}
                Heading={"Enter mobile number"}
                SubHeading={"OTP will be sent to this number for verification"}
                OnClose={() => {props.toggleAddPhonenumberAndReonboardModal()}}
                Fields = {phoneNumber}
                SetFields = {setPhoneNumber}
                Buttons={getButtons()}
                InputType= {MODAL_TYPE_VERIFY_NUMBER}
                loading={togglers.loading}
                newModal={true}
                />
            case ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER_VERIFY_OTP:
                return  <LoginScreens 
                ImageUrl={process.env.PUBLIC_URL + "/assets/images/OTPLogin.png"}
                Heading={"Enter verification code"}
                SubHeading={"6 digit OTP has been sent to +91 " + phoneNumber.value}
                OnClose={() => {props.toggleAddPhonenumberAndReonboardModal()}}
                Fields = {otp}
                SetFields = {setOtp}
                Buttons={getButtons()}
                InputType= {MODAL_TYPE_VERIFY_OTP}
                renderModalInputSubHeading={renderOTPInputSubHeading}
                loading={togglers.loading}
                newModal={true}
                />
            default : 
            return <></>
        }
    }
    return (<div>
    <Modal
    visible={props.isOpen}
    onClose={()=>{}}
    className={togglers.currentType===MODAL_TYPE_SIGN_UP_OTP ? `${styles.loginModalsLarge}`:togglers.currentType===GET_PERSONAL_DETAILS ?`${styles.personalDetailsModal}`:togglers.currentType===GET_RES_LOCATION_DETAILS_2 ?`${styles.restaurantLocationModal}`:`${styles.loginModals}`}
    isCentered
    >
        {getCurrentScreen()}
    </Modal>
    <Modal
    visible={finalStateModal.Open}
    onClose={()=>{}}
    className={`${styles.finalStateModal}`}
    isCentered
    >
        {getFinalStateModal()}
    </Modal>
    <Modal
    visible={togglers.currentType === COMPLETE_ONBOARDING_SETUP && props.completeSetupModalOpen}
    onClose={()=>{}}
    className={props?.completeSetupModalData?.Heading !== RESTAURANT_SETUP && props?.completeSetupModalData?.SecondaryCTA?.Text !==LOGOUT ?`${styles.onboardingModalLarge}`:`${styles.onboardingModal}`}
    isCentered
    >
        {getOnboardingsetUpModal()}
    </Modal>
    <Modal
    visible={props.actionType === ADD_LOCATION_SIGN_UP}
    onClose={()=>props.setLocationModal(false)}
    className={`${styles.onboardingModal}`}
    isCentered
    >
        {getLocationSignUpModal()}
    </Modal>
    <Modal
    visible={props.actionType === ADD_USER_PHONE_NUMBER_AND_REONBOARD_USER}
    onClose={() => {props.toggleAddPhonenumberAndReonboardModal()}}
    className={`${styles.onboardingModal}`}
    isCentered
    >
        {getCurrentScreen()}
    </Modal>
    </div>)
}
export default withRouter(
    connect(
      (state) => ({
        verifyUserData: state.user.verifyUserData,
        verifyUserOTPData: state.user.verifyUserOTPData,
        signInResponse: state.auth.signInResponse,
        addressLocationDetails: state.user.adressLocationDetails || {},
        searchLocations: state.user.searchLocations || {},
        locationMeta:  {},
        userData: state.user.data,
        showLocationModal: state.auth.showLocationModal ?state.auth.showLocationModal : state.user.showLocationModal,
        ownerDetailsData: state.user.owner_details,
        documentVerificationDetails: state.user.document_verification_details,
        documentNumberVerificationDetails: state.user.document_number_verification_details,
        uploadDocumentDetails: state.user.upload_document_details,
        pollDocumentsDetails: state.user.poll_document_details,
        addPhoneNumberAndReonboardUser: state.user.addPhoneNumberAndReonboardUser,

        //status
        verifyUserStatus: state.status.verify_user,
        verifyUserOTPStatus: state.status.verify_user_otp,
        signInStatus: state.status.auth_signIn,
        sendSmsOTPStatus: state.status.fogotPassword_enterPhone,
        forgetPasswordForWebStatus: state.status.forgotPassword_verifyOtp,
        getAddressDetailsStatus: state.status.get_address_details || {},
        getSearchLocationStatus: state.status.get_search_locations || {},
        updateOutletLocationStatus: state.status.update_outlet_location || {},
        updateOutletLocationResponse: state.user.updateLocation || {},
        getOwnerDetailsStatus: state.status.owner_details || {},
        documentVerificationDetailsState: state.status.document_verification_details || {},
        documentNumberVerificationDetailsState: state.status.document_number_verification_details,
        uploadDocumentDetailsState: state.status.upload_document_details,
        pollDocumentDetailsState: state.status.poll_document_details || {},
        addUserPhoneNumberAndReonboardStatus: state.status.add_user_phone_number_and_reonboard_user,
        addUserPhoneNumberAndReonboardVerifyOTPStatus: state.status.add_user_phone_number_and_reonboard_user_verify_otp,
      }),

      (dispatch) => ({
        actions: bindActionCreators({
            verifyUser,
            signIn,
            resetVerifyUserData,
            verifyUserOtp,
            forgotPasswordEnterPhone,
            forgotPasswordVerifyOtp,
            resetVerifyUserOTPData,
            trackingWithoutAuth,
            completePersonalDetails,
            completeRestaurantDetails,
            documentVerificationViewDetails,
            getAddressDetailsUsingLatLong,
            getSearchLocations,
            verifyPanAndLegalEntityName,
            completeUploadDetails,
            trackPopUp,
            getUserData,
            getOwnerDetails,
            callCustomEndpoint,
            uploadDocuments,
            pollDocuments,
            addUserPhoneNumberAndReonboard,
            addUserPhoneNumberAndReonboardVerifyOTP
        },
          dispatch
        ),
      })
    )(SignInFlow)
  );