import actionTypes from "../actionTypes";

function common(state = {}, action) {
  const { payload, type } = action;

  switch (type) {
    case actionTypes.CATALOG_ACTION_SET:
        return {
            ...state,
            catalogAction: payload
        }
    default:
      return state;
  }
}

export default common;
